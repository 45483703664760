import { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

import Monetize from '../../images/monetize-eng.png';


import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';


function Third () {
  

  return (
    <div className="p-4 py-16 lg:py-24 mt-20 lg:mt-28 bg-[#F7F9F4]">
        <div className='flex flex-col lg:flex-row justify-between items-center max-w-6xl m-auto'>
            <div className='flex flex-col items-start'>
                <div className='flex flex-col mb-9'>
                    <AnimationOnScroll animateIn="slideInRight" duration="0.5"><h1 className='text-[#121B00] pb-0 text-4xl lg:text-5xl ml-0 font-black text-center lg:text-start mb-4'>MONETIZE YOUR <br />ENGAGEMENTS</h1></AnimationOnScroll>
                    <p className='text-sm lg:text-base text-center lg:text-start text-neutral-800 max-w-md'>Get one platform to sell all of your digital products, and receive payments from anyone, anywhere.</p>
                </div>
                <Link to='/register' className='bg-[#121B00] flex m-auto lg:m-0 mb-16 text-white text-sm hover:text-black hover:bg-white hover:border-2 hover:border-[#121B00] px-11 py-3.5 rounded-full'>Get started - It's free</Link>
            </div>

            <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='p-9 px-8 pl-6 pt-4 lg:p-10 lg:pl-16 lg:w-5/12 bg-[#121B00] flex flex-col lg:flex-row rounded-4xl justify-between items-center'>
                <img src={Monetize} className='w-full' alt='Monetize with Prymshare' />
            </AnimationOnScroll>
        </div>
      
    </div>
  );
}

export default Third;
