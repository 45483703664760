import React, {useState} from "react"
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TECollapse,
} from "tw-elements-react";


import { creatorlinks } from "../../../state/atoms/Creator";

import { addCreatorLinks } from '../../../state/actions/creator';



const PaywallModal = ({ showPaywallModal, setShowPaywallModal, loadingPrice, setLoadingPrice, price, setPrice }) => {

    const savePrice = () => {
        setLoadingPrice(true);
         // Set a 2-second delay before hiding the paywall modal
        setTimeout(() => {
            setShowPaywallModal(false);
            setLoadingPrice(false);
        }, 1500);
    }

    return(
        <div className="">
          <TEModal show={showPaywallModal} setShow={setShowPaywallModal} scrollable>
            <TEModalDialog className='!p-3 !m-auto'>
                <TEModalContent>
                    {loadingPrice ? 
                        <div className="p-12 h-[300px]">
                            <div
                                className="flex m-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                <span
                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                >Loading...</span>
                            </div> 
                        </div>
                        :
                        <>
                        <TEModalHeader>
                            {/* <!--Modal title--> */}
                            <h5 className="text-medium font-bold leading-normal text-neutral-800 dark:text-neutral-200">
                            Set Price
                            </h5>
                            {/* <!--Close button--> */}
                            <button
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={() => setShowPaywallModal(false)}
                            aria-label="Close"
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody>    
                            <form>           
                            <div>
                                <label htmlFor="email" className="!font-bold">
                                Price
                                </label>
                                
                                <div className="mt-2 flex items-center">
                                    <select className="form-select p-2 cursor-pointer !w-4/12" disabled>
                                        <option className="text-sm" value="NGN">NGN</option>
                                    </select>
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        value={price}
                                        onChange={e => setPrice(e.target.value)}
                                        className="w-8/12"
                                    />
                                </div>
                            </div>

                            <p className="flex text-xs mt-2 text-[#6F7466]">This is a one time payment that your audience pay once to access this link.</p>

                            <div className='submit-comp !w-full !ml-0 text-center'> 
                                <button
                                    onClick={savePrice}
                                    className="complete-button !justify-center !ml-0"
                                >
                                    Save
                                </button>
                            
                            </div>
                            </form>

                        </TEModalBody>
                        <TEModalFooter>
                            <TERipple rippleColor="light">
                            {/* <button
                                type="button"
                                className="inline-block rounded bg-primaryy-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primaryy-700 transition duration-150 ease-in-out hover:bg-primaryy-accent-100 focus:bg-primaryy-accent-100 focus:outline-none focus:ring-0 active:bg-primaryy-accent-200"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button> */}
                            </TERipple>
                            
                        </TEModalFooter>
                        </> 
                    }
                    
                </TEModalContent>
            </TEModalDialog>
            </TEModal>
        </div>
    )
}

export default PaywallModal;