import { useEffect } from 'react';
import axios from 'axios';

const usePageViewTracker = (username) => {
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/profile/track-page-view/`, { username })
            .then(response => console.log('Page view is recorded:', response.data))
            .catch(error => console.error('Error recording page view:', error));
    }, [username]);
};

export default usePageViewTracker;