import { useEffect, useState } from 'react';
// import Navbar from '../components/header/navbar';
import {
	Outlet,
	Link,
	useLocation

} from "react-router-dom";
import queryString from 'query-string';

import { useRecoilState, useRecoilValue } from 'recoil';
import { authenticated } from "./../state/atoms/Authenticated";
import { access } from "./../state/atoms/Authenticated";
import { refresh } from "./../state/atoms/Authenticated";
// import { loggedcreator, loggeddname, loggeduname } from "./../state/atoms/Creator";
import { loadProfile } from './../state/actions/creator';

import { googleAuthenticate ,checkAuth, logout } from './../state/actions/auth'
import Navbar from '../components/header/navbar.tsx';


const Layout = () => {
	let location = useLocation();

	const [authState, setAuthState] = useRecoilState(authenticated);
	const [accessState, setAccessState] = useRecoilState(access);
	const [refreshState, setRefreshState] = useRecoilState(refresh);
	
	// const [loggedCreatorDname, setLoggedCreatorDname] = useRecoilState(loggeddname);
	// const [loggedCreatorUname, setLoggedCreatorUname] = useRecoilState(loggeduname);
	// const [loggedCreator, setLoggedCreator] = useRecoilState(loggedcreator);
	// const [loggedCreatorDname, setLoggedCreatorDname] = useRecoilState(loggeddname);
	// const [loggedCreatorUname, setLoggedCreatorUname] = useRecoilState(loggeduname);
	const isAuth = useRecoilValue(authenticated);


	useEffect(() => {
        const values = queryString.parse(location.search);
        const state = values.state ? values.state : null;
        const code = values.code ? values.code : null;

        console.log('State: ' + state);
        console.log('Code: ' + code);

        if (state && code) {
        	// console.log(code+'again');
            googleAuthenticate(state, code, setAuthState, setAccessState, setRefreshState);
        }

        // console.log(window.location.pathname);
        
    }, [location]);

	useEffect(() => {
		console.log('Checking verification for user!');
      	checkAuth(setAuthState);
		// loadProfile(setLoggedCreator, setLoggedCreatorDname, setLoggedCreatorUname);
      	console.log(authState);
      	console.log(isAuth);
  	}, []);


	return (
    <div>
		<Navbar isAuth={isAuth} setAuthState={setAuthState} setRefreshState={setRefreshState} setAccessState={setAccessState} />
		<Outlet />
    </div>
	);
}

export default Layout;