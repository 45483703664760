import axios from 'axios';
import { redirect } from "react-router-dom";

// import { getShowAlert } from './alertActions';

axios.defaults.withCredentials = true;





export const signIn = async(email, password, setAuthState, setAccessState, setRefreshState, setLoading) => {
	// console.log('page2 login');
	let response = await fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });
  let data = await response.json();

  if (response.status === 200) {
  	// console.log('maybe set')
    setAuthState(true);
    // console.log('set')
    // console.log(data.access);
    setAccessState(localStorage.setItem('access', data.access));
    setRefreshState(localStorage.setItem('refresh', data.refresh));
    // console.log('token set')
    // return redirect("/");
    // console.log(data.refresh);
  } else {
    setLoading(false);
    // console.log(data);
    alert('Something went wrong. Please try again.')
    console.log('error');
  }
  
};

export const signUp = async( email, password, re_password, setAuthState, setLoading, navigate) => {
  // const showAlert = getShowAlert();
	// console.log('page2 login');
	let response = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      re_password: re_password
    }),
  });
  let data = await response.json();
  
  console.log(response)
  if ( response.status === 201) {
  	// console.log('maybe set')
    setAuthState(false);
    alert('You have been registered successfully. You have been sent an activation email to activate your account, after which you can log in!')
    setLoading(false);
    navigate('/check-mail')
    // showAlert('You have been registered successfully. You have been sent an activation email to activate your account, after which you can log in!', 'success')
    // console.log('token set')
    // return redirect("/");
    // console.log(data.refresh);
  } else {
    // console.log(data);
    setLoading(false)
    alert('Something went wrong. Please try again.')
    console.log('error');
  }
  
};

export const verify = async(uid, token, navigate, setLoading) => {
  // const showAlert = getShowAlert();
	// console.log('page2 login');
	let response = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: uid,
      token: token
    }),
  });


  if (response.status === 204) {
    alert('You account has been activated successfully. You can now log in!')
    navigate('/login')
  } else {
    // console.log(data);
    alert('Something went wrong. Please try again!')
    setLoading(false);
    console.log('error');
  }
  
};

export const reset_password = async(email, navigate, setLoading) => {
  // const showAlert = getShowAlert();
	// console.log('page2 login');
	let response = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email
    }),
  });

  console.log(response);

  if (response.status === 204) {
    alert('Success! An email has been sent to you! ')
    navigate('/login')
  } else {
    // console.log(data);
    alert('Something went wrong. Please try again!')
    setLoading(false);
    console.log('error');
  }
  
};

export const reset_password_confirm = async(uid, token, new_password, re_new_password, navigate, setLoading) => {
  // const showAlert = getShowAlert();
	// console.log('page2 login');
	let response = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      new_password: new_password,
      re_new_password: re_new_password
    }),
  });


  if (response.status === 204) {
    alert('Your Password has been reset!')
    navigate('/login')
  } else {
    // console.log(data);
    alert('Something went wrong. Please try again!')
    setLoading(false);
    console.log('error');
  }
  
};


export const handleGoogleLogin = async(response, setAuthState, setAccessState, setRefreshState) => {
    console.log(response);
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/convert-token`, {
        token: response.access_token,
        backend: "google-oauth2",
        grant_type: "convert_token",
        client_id: `${process.env.REACT_APP_DRF_LOCAL_CLIENT_ID}`,
        client_secret: `${process.env.REACT_APP_DRF_LOCAL_CLIENT_SECRET}`,
      })
      .then((res) => {
        const { access_token, refresh_token } = res.data;
        // console.log({ access_token, refresh_token });
        // localStorage.setItem("access_token", access_token);
        // localStorage.setItem("refresh_token", refresh_token);
        setAuthState(true);
		    setAccessState(localStorage.setItem('access', access_token));
		    setRefreshState(localStorage.setItem('refresh', refresh_token));
      })
      .catch((err) => {
        console.log("Error Google login", err);
      });
  };



export const checkAuth = async(setAuthState) => {
	console.log('Checking in  progress')
	if (localStorage.getItem('access')) {
		let response = await fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, {
	    method: "POST",
	    headers: {
	      "Content-Type": "application/json",
        'Accept': 'application/json'
	    },
	    body: JSON.stringify({
	      token: localStorage.getItem('access'),
	    }),
	  });
	  let data = await response.json();

	  console.log('I am indeed checking');

	  if (data.code !== 'token_not_valid') {
	    setAuthState(true);
	    // console.log(data.code);
	  } else {
	    // console.log(data);
	    setAuthState(false);
	    // console.log('error');
	  }
	}	else {
		setAuthState(false);
	}
	console.log('Checked!')
  
};


export const loadUser = async(setUserId, setFirstName, setLastName, setUserEmail) => {
	console.log('Checking in  progress')
	if (localStorage.getItem('access')) {
		let response = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/me/`, {
	    method: "GET",
	    headers: {
	      "Content-Type": "application/json",
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
	    },
	    
	  });
	  let data = await response.json();
    setUserId(data.id);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setUserEmail(data.email);
	  console.log(data);

	 
	}	else {
		// setAuthState(false);
	}
	console.log('Checked!')
  
};





// export const googleAuthenticate = async(state, code, setAuthState, setAccessState, setRefreshState) => {
// 	// console.log('hey');
// 	const details = {
// 	  'state': state,
// 	  'code': code
// 	};
// 	const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');
// 	// console.log('hey pass');
// 	let response = await fetch(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?${formBody}`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//     },
   
//   });
//   let data = await response.json();
// 	// console.log('hey pass 2');
//   if (response.status === 200) {
//     setAuthState(true);
//     setAccessState(localStorage.setItem('access', data.access));
//     setRefreshState(localStorage.setItem('refresh', data.refresh));
//     // return redirect("/");
//     // console.log(data.refresh);
//   } else {
//     // console.log(data);
//     console.log('error');
//   }
  
// };

export const googleAuthenticate = async(state, code, setAuthState, setAccessState, setRefreshState) => {
    // console.log(response);
    const details = {
        'state': state,
        'code': code
    };

    const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

    await axios
      .post(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?${formBody}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        }
    })
      .then((res) => {
        setAuthState(true);
        setAccessState(localStorage.setItem('access', res.data.access));
        setRefreshState(localStorage.setItem('refresh', res.data.refresh));
      })
      .catch((err) => {
        console.log("Error Google login", err);
      });
  };



export const logout = (setAuthState, setAccessState, setRefreshState) => {
	// console.log('logged out');
	setAuthState(false);
	localStorage.removeItem('access');
	localStorage.removeItem('refresh');
	setAccessState(null);
	setRefreshState(null);
  // setLoggedCreator('loading');
};