import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';

import './home.css';
import {useRecoilValue} from 'recoil';
import { authenticated } from "../../state/atoms/Authenticated";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



import SignArrow from '../../images/circular-arrow.png';

import Frame1 from '../../images/pFrame1.png';
import Frame2 from '../../images/pFrame2.png';
import Frame3 from '../../images/pFrame3.png';
import Frame4 from '../../images/pFrame4.png';
import Frame5 from '../../images/pFrame5.png';
import Frame6 from '../../images/pFrame6.png';
import Frame7 from '../../images/pFrame7.png';
import Frame8 from '../../images/Frame8.png';
import Frame9 from '../../images/Frame9.png';
import Frame10 from '../../images/Frame10.png';

import Podcaster from '../../images/podcaster.png';
import Influencer from '../../images/influencer.png';
import Vlogger from '../../images/vlogger.png';
import Host from '../../images/host.png';
import Comedian from '../../images/comedian.png';
import Artist from '../../images/artist.png';
import Animator from '../../images/animator.png';
import BrandRep from '../../images/brandrep.png';




import Second from './secondlp.tsx';
import Third from './thirdlp.tsx';
import Fourth from './fourthlp.tsx';
import Fifth from './fifthlp.tsx';
import Footer from './footerlp.tsx';

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import ReactGA from "react-ga4";

import { Helmet } from 'react-helmet';
// import { IonSpinner } from '@ionic/react';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
};

function Home() {
  usePageTracking();
  let navigate = useNavigate();
  

  const isAuth = useRecoilValue(authenticated);

  const [username, setUsername] = useState('')

  const [loading, setLoading] = useState(false);
  // const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('Join Waitlist');

  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    let scrollAmount = 0;

    const scrollStep = () => {
      scrollAmount += 1;
      if (scrollAmount >= carousel.scrollWidth / 2) {
        scrollAmount = 0;
      }
      carousel.scrollLeft = scrollAmount;
    };

    const interval = setInterval(scrollStep, 20);

    return () => clearInterval(interval);
  }, []);


  const join = (e) => {
    // e.preventDefault();
    // console.log('waiting');
    navigate('/register');
    // if(email == '') {
    //   setError("Please enter a valid email!");
    // } else if(email.includes('.com') == false) {
    //   setError("Please enter a valid email!");
    // } else {
    //   joinList();
    // }
}

const joinList = () => {
    // e.preventDefault();
    setLoading(true);
    setSuccess('....');
    Axios({
        url: `${process.env.REACT_APP_API_URL}/api/profile/join-waitlist/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
       data: {
          email: email,
      }
    }).then(response => {
      setError('');
      setSuccess("Congratulations!");
      setLoading(false)
      setEmail('')
      let data = response.data
      if (data['error'] === false) {
        //console.log('Error There. What did you do wrong now??')
      }
    }).catch(error => {
      //console.log(error);
    })

}

  const handleClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked My Button',
      label: 'Button Click',
    });
    navigate('/register');
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 700
  };

  const settingsMobile = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 700
  };

  const imagesInit = [
    Frame1,
    Frame2,
    Frame3,
    Frame4,
    Frame5,
    Frame6,
    Frame7,
    Frame8,
    Frame9,
    Frame10,
  ];

  const images = [
    { front: Frame3, back: Influencer },
    { front: Frame1, back: Podcaster },
    { front: Frame2, back: Frame5 },
    { front: Frame4, back: Artist },
    { front: Frame5, back: Frame5 },
    { front: Frame6, back: Host },
    { front: Frame7, back: Animator },
    { front: Frame8, back: Vlogger },
    { front: Frame9, back: Comedian },
    { front: Frame10, back: BrandRep },
  ];

  return (
    <>
    <Helmet>
      <title>Prymshare || Flex your online brand like a pro!</title>
      <meta
          name='description'
          content="Link-in-bio, sell your content, and monetize your virtual events"
      />
    </Helmet>
    <AnimationOnScroll animateIn="fadeIn" duration="1" className='reative box-border'>
      <div className="bg-[#121B00] pt-32 lg:pt-36 h-full relative">
        {/*{isAuth ? <h1>This is the Home for logged in user</h1> : <h1>Not logged in home page</h1>}*/}
        {/* <div className='hidden lg:flex justify-between items-center absolute m-auto b-20 positioning max-w-4xl'>
          <img src={VideoPlayer} className='w-2/12 mt-7' alt='Prym Video Player'/>
          <img src={Frame} className='w-3/12 ml-5' alt='Prymshare Frame' />
          <img src={AudioPlayer} className='w-3/12 mt-5' alt='Prym Audio Player' />
        </div> */}
        <AnimationOnScroll animateIn="bounceIn" duration="3" className='p-3 mb-4 lg:mb-7'>
          <h1 className='text-neutral-100 text-5xl lg:text-7xl font-black text-center'>SHOW, <br className='lg:hidden' />MONETIZE <br className='' />AND GROW. <br className='lg:hidden' /> ALL IN <br className='hidden lg:block' /> ONE<br className='lg:hidden' /> SIMPLE PAGE</h1>
          
          <p className='text-sm lg:text-base text-center text-neutral-100 p-4 pb-0 lg:hidden hidden'>Showcase your brand, sell your content, add paywalls, and manage ticketing for your virtual events.  </p>
        </AnimationOnScroll>

        <div className='flex flex-col p-3 max-w-lg justify-center m-auto lg:justify-between lg:max-w-xl lg:flex-row lg:items-center lg:mt-4 lg:pt-3 box-border'>
          <div className="flex px-4 py-2 justify-between items-center font-bold lg:mr-3 rounded-full  bg-white text-base lg:text-xl lg:px-4 lg:py-2 lg:w-full lg:mt-0">
              <div className='flex items-center'>
                <p className="text-neutral-400">
                  prymshare.co/
                </p>
                <p><input className="m-0 p-0 w-full border-0 font-bold placeholder:text-neutral-400 outline-0 outline-none"
                  type='text' name='username' value={username} placeholder="username" onChange={(e) => setUsername(e.target.value)}
                /></p>
              </div>
              <div onClick={handleClick} className='p-2 rounded-full lg:p-2 bg-primaryy-500 cursor-pointer'>
                <img src={SignArrow} className='block w-5 lg:w-7' alt='Sign up button' />
              </div>
          </div>

          {/* <input className="flex px-5 py-3 mt-10 lg:mr-3 rounded-full border-2 border-primaryy-300 bg-white text-sm lg:px-4 lg:py-3 lg:w-full lg:mt-0 font-medium placeholder:text-neutral-400 outline-0"
                type='email' name='email' value={email} placeholder="Enter your username" onChange={(e) => setEmail(e.target.value)}
              />

          <div className='flex justify-between mt-3 text-center w-full lg:w-5/12 lg:mt-0'>
            
            <p onClick={(e) => join()} className='block cursor-pointer w-full px-6 py-4 lg:px-5 lg:py-3 text-sm font-medium text-white bg-[radial-gradient(circle_at_right,_var(--tw-gradient-stops))] from-accent-500 to-primaryy-500 hover:bg-secondaryy-600 rounded-full'>Sign up!</p>
          </div> */}
          
        </div>
        {/*<p className='text-sm text-center p2 text-secondaryy-200'>{error}</p>*/}
        

      {/* <div className="mt-14 mb-9 lg:mt-24 lg:mb-10 carousel-container max-w-4xl m-auto bg-[#1C2704] p-2 rounded-lg lg:rounded-xl box-border" ref={carouselRef}>
        <div className="carousel-wrapper box-border">
          {imagesInit.map((image, index) => (
            <div className="carousel-item box-border" key={index}>
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
          {imagesInit.map((image, index) => (
            <div className="carousel-item box-border" key={index + images.length}>
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </div>
      </div> */}

      <div className="mt-14 mb-9 lg:mt-24 lg:mb-10 carousel-container max-w-4xl m-auto bg-[#1C2704] p-2 rounded-lg lg:rounded-xl box-border" ref={carouselRef}>
        <div className="carousel-wrapper box-border">
          {images.map((image, index) => (
            <div className="carousel-item" key={index}>
              <div className="image-flip">
                <div className="image-front">
                  <img src={image.front} alt={`Slide ${index}`} />
                </div>
                <div className="image-back">
                  <img src={image.back} alt={`Slide ${index} back`} />
                </div>
              </div>
            </div>
          ))}
          {images.map((image, index) => (
            <div className="carousel-item" key={index + images.length}>
              <div className="image-flip">
                <div className="image-front">
                  <img src={image.front} alt={`Slide ${index}`} />
                </div>
                <div className="image-back">
                  <img src={image.back} alt={`Slide ${index} back`} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

        <div className='p-3 pb-12'>
          <p className='text-xs text-center text-neutral-400 font-semibold'>One link to share everything | Built for African creators</p>
        </div>
        
      </div>
      
      

      <Second />
      <Third />
      <Fourth />
      <Fifth />
      <Footer />
    </AnimationOnScroll>
    </>
  );
}

export default Home;
