import axios from 'axios';
import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { authenticated } from "./Authenticated"
import { authenticated } from "../../state/atoms/Authenticated";
import { access } from "../../state/atoms/Authenticated";
import { refresh } from "../../state/atoms/Authenticated";
import { loggedcreator } from "../../state/atoms/Creator";
// import Home from '../containers/Home/home.tsx'
import {
  Link
} from "react-router-dom";
import { redirect, useNavigate  } from "react-router-dom";

import { reset_password, handleGoogleLogin } from '../../state/actions/auth';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';

import Loginpic from '../../images/login-pic.png';
import Logo from '../../images/new_logo.png';
import LogoM from '../../images/logo-main.png';
import GoogleLogo from '../../images/google.png';
// import GoogleLogin from "react-google-signup";


function ResetPassword() {
  let navigate = useNavigate();

  const [authState, setAuthState] = useRecoilState(authenticated);
  const [accessState, setAccessState] = useRecoilState(access);
  const [refreshState, setRefreshState] = useRecoilState(refresh);
  
  const [googleLoading, setGoogleLoading] = useState(false);
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [re_password, setRe_Password] = useState('')

  const [username, setUsername] = useState('')
 
  const [userNameAvailable, setUserNameAvailable] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)
  const [pwordMessage, setPwordMessage] = useState(null)

  const isAuth = useRecoilValue(authenticated);
  const isCreatorAlready = useRecoilValue(loggedcreator);

  const onEmailChange = e => setEmail(e.target.value );
 
  
  const onSubmit = e => {
    e.preventDefault();
    setLoading(true)
    // console.log('signup');
    reset_password(email, navigate, setLoading);
  };

  return (
    <div className="signup">
      <div className="signup-section2">
        <div className="flex justify-between items-center mt-3">
          <img className='logo hidden lg:flex' src={Logo} />
          <img className='logo flex lg:hidden' style={{width: '35px'}} src={LogoM} />
          <p className="bg-[#EEF1E8] text-[#505449] p-3 py-2 text-xs rounded-full">Already a Prymer? <Link to="/login" className='text-[#F84F94]'>Sign in</Link></p>
        </div>

        <div className="signup-texts">
          <h1 className='font-black text-[#121B00] tracking-tight'>RESET PASSWORD</h1>
          <p className='text-xs text-[#6F7466]'>Enter your registered email</p>
        </div>
        

        <form className="" action="#" onSubmit={e => onSubmit(e)}>
          <div>
          <div className="mt-4">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder='Your Email address'
                required
                value={email}
                onChange={e => onEmailChange(e)}
                className=""
              />
            </div>
          </div>
          <div className='mt-9'>
            <button
              type="submit"
              className="flex w-full justify-center rounded-full bg-[#121B00] px-3 py-3 text-sm leading-6 text-white shadow-sm hover:text-black hover:bg-white hover:border-2 hover:border-[#121B00]"
            >
              {loading ? <div
                          className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          role="status">
                          <span
                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                          >Loading...</span>
                        </div> : <p>Reset Password</p>
                      }
            </button>
          </div>
        </form>

      </div>
    </div>
  );
}

export default ResetPassword;
