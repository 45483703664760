import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App.tsx';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  StateProvider
} from './stateProvider.tsx';
import { RecoilRoot } from 'recoil';
import { GoogleOAuthProvider } from '@react-oauth/google';
import reducer, { initialState } from './reducer.tsx';
import ReactGA from "react-ga4";
import Alert from './components/Alert';


ReactGA.initialize('G-VPP72Q7K07');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
  	<GoogleOAuthProvider clientId='727747960184-i3jrs7h05om410fj6oett5q1d21rapsk.apps.googleusercontent.com'>
	  	<BrowserRouter>
		  	<Alert />
	  		<App />
	  	</BrowserRouter>
	</GoogleOAuthProvider>
  </RecoilRoot>
);
	
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

