import React, { useEffect, useState } from "react";
import { TEChart } from "tw-elements-react";
import axios from 'axios';
import './analytics.css';

function Analytics() {
  const [data, setData] = useState({ views: [], clicks: [], ctr: [] });
  const [analytics, setAnalytics] = useState({
    total_page_views: 0,
    unique_page_views: 0,
    total_link_clicks: 0,
    click_through_rate: 0,
  });

  useEffect(() => {
    // const creatorId = 1; // Replace with the actual creator ID
    const config = {
      headers: {
          'Content-Type': 'multipart/formdata',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          // 'Accept': 'multipart/formdata'
      } 
    }; 
    axios.get(`${process.env.REACT_APP_API_URL}/api/profile/get-analytics/`, config)
      .then(response => {
        const views = response.data.views_per_week.map(week => week.count);
        const clicks = response.data.clicks_per_week.map(week => week.count);
        setData({ views, clicks });
        setAnalytics({
          total_page_views: response.data.total_page_views,
          unique_page_views: response.data.unique_page_views,
          total_link_clicks: response.data.total_link_clicks,
          click_through_rate: response.data.click_through_rate,
        });
      })
      .catch(error => console.error('Error fetching analytics:', error));
  }, []);

  return (
    <div className="analytics">
      {/* <div className="analytics-overview mt-16">
        
        <p>Total Link Clicks: {analytics.total_link_clicks}</p>
        <p>Click Through Rate: {analytics.click_through_rate}</p>
      </div> */}

      <div className="page-views">
          <div className="stat-words">
            <h4>Total Page Views</h4>
            {/* <p>Unique Page Views: {analytics.unique_page_views}</p> */}
            {/* <h2>{analytics.total_page_views} </h2> */}
            <h2>{analytics.unique_page_views}</h2>
            <p>Last 30 days</p>
          </div>
          <div className="stat-chart">
            <TEChart
              type="line"
              data={{
                labels: [
                  "Week 1",
                  "Week 2",
                  "Week 3",
                  "Week 4",
                ],
                datasets: [
                  {
                    label: "Views per Week",
                    data: data.views,
                    borderColor: '#00D37F',
                    backgroundColor: '#D6FEEE',
                  },
                ],
              }}
            />
          </div>
      </div>

      <div className="page-views">
          <div className="stat-words">
            <h4>Total Links Clicks</h4>
            {/* <p>Unique Page Views: {analytics.unique_page_views}</p> */}
            <h2>{analytics.total_link_clicks}</h2>
            <p>Last 30 days</p>
          </div>
          <div className="stat-chart">
            <TEChart
              type="line"
              data={{
                labels: [
                  "Week 1",
                  "Week 2",
                  "Week 3",
                  "Week 4",
                ],
                datasets: [
                  {
                    label: "Clicks per Week",
                    data: data.clicks,
                    borderColor: '#EF4444',
                    backgroundColor: '#FEE2E2',
                  },
                ],
              }}
            />
          </div>
      </div>

      <div className="page-views">
          <div className="stat-words">
            <h4>Click Through Rate</h4>
            {/* <p>Unique Page Views: {analytics.unique_page_views}</p> */}
            {/* Rounded to 2 decimal places */}
            <h2>{(Math.ceil( analytics.click_through_rate * Math.pow(10, 2)) / Math.pow(10, 2))}</h2> 
            <p>Last 30 days</p>
          </div>
          <div className="stat-chart">
            <TEChart
              type="line"
              data={{
                labels: [
                  "Week 1",
                  "Week 2",
                  "Week 3",
                  "Week 4",
                ],
                datasets: [
                  {
                    label: "Clicks per Week",
                    data: data.ctr,
                    borderColor: '#EF4444',
                    backgroundColor: '#FEE2E2',
                  },
                ],
              }}
            />
          </div>
      </div>

      
    </div>
  );
}

export default Analytics;