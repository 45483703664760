import React, { useState, useEffect } from 'react';
import { redirect, useNavigate, Link, useParams } from "react-router-dom";
import { verify, handleGoogleLogin } from '../../state/actions/auth';


import Loginpic from '../../images/login-pic.png';
import Logo from '../../images/new_logo.png';
import LogoM from '../../images/logo-main.png';
import GoogleLogo from '../../images/google.png';



const Activate = () => {
    let navigate = useNavigate();
    const { uid, token } = useParams();

    const [loading, setLoading] = useState(false);

    const verify_account = e => {
        setLoading(true);
        verify(uid, token, navigate, setLoading);
    };


    // useEffect(() => {
    //     if (verified) {
    //         navigate('/login');
    //     }
    // }, [verified]);

    return (

        <div className="signup">
            <div className="signup-section2">
                <div className="flex justify-between items-center mt-3">
                    <img className='logo hidden lg:flex' src={Logo} />
                    <img className='logo flex lg:hidden' style={{width: '35px'}} src={LogoM} />
                    <p className="bg-[#EEF1E8] text-[#505449] p-3 py-2 text-xs rounded-full">Already a Prymer? <Link to="/login" className='text-[#F84F94]'>Sign in</Link></p>
                </div>
  
                <div className="signup-texts">
                    <h1 className='font-black text-[#121B00] tracking-tight text-center'>ACTIVATE YOUR ACCOUNT</h1>
                    <p className='text-xs text-[#6F7466] text-center'>Click on the button to verify your account & sign in.</p>
                </div>
                
                <div className='mt-9'>
                    <button
                        onClick={verify_account}
                        className="flex w-full justify-center rounded-full bg-[#121B00] px-3 py-3 text-sm leading-6 text-white shadow-sm hover:text-black hover:bg-white hover:border-2 hover:border-[#121B00]"
                    >
                        {loading ? <div
                                    className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                    role="status">
                                    <span
                                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                    >Loading...</span>
                                </div> : <p>Activate account</p>
                                }
                    </button>
                </div>     
            </div>
      </div>
    );
};

export default Activate;
