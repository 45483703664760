import {atom} from 'recoil'


export const authenticated = atom({
  key: 'authenticated', // unique ID (with respect to other atoms/selectors)
  default: 'loading', // default value (aka initial value)
});

export const access = atom({
  key: 'access',
  default: localStorage.getItem('access'), 
});

export const refresh = atom({
  key: 'refresh', 
  default: localStorage.getItem('refresh'), 
});

export const userid = atom({
  key: 'userid', 
  default: null,
});

export const firstname = atom({
  key: 'firstname', 
  default: null,
});

export const lastname = atom({
  key: 'lastname', 
  default: null,
});

export const email = atom({
  key: 'email', 
  default: null,
});