import React, { useState, useContext } from 'react';
import axios from 'axios';


const Feedback = () => {
  const [title, setTitle] = useState('');
  const [feedback, setFeedback] = useState('');
  const [message, setMessage] = useState('');

  const [sending, setSending] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/profile/submit-feedback/`,
        { title, feedback },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                // 'Accept': 'multipart/formdata'
            },
        }
      );
      if (response.status === 201) {
        setMessage('Feedback submitted successfully!');
        setTitle('');
        setFeedback('');
        setSending(false);
      }
    } catch (error) {
      setMessage('Error submitting feedback. Please try again.');
      setSending(false);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md"  style={{fontSize: '75%'}}>
      <h2 className="text-xl font-bold mb-4">Submit Feedback</h2>
      
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="title">
            Title
          </label>
          <input
            type="text"
            id="title"
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="feedback">
            Feedback
          </label>
          <textarea
            id="feedback"
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
          ></textarea>
        </div>
        {/* <button
          type="submit"
          className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Submit
        </button> */}
        {message && <p className="mb-4 p-4 rounded-full bg-primary-500 text-center text-green-500">{message}</p>}
        
        <div className='submit-comp'>
            <button
                type="submit"
                className="complete-button w-full text-center"
                style={{fontSize: '100%'}}
            >
            { sending ? 'Sending...' : 'Submit' }
            </button>
        </div>

      </form>
    </div>
  );
};

export default Feedback;
