
function Terms() {
  return (
    <main className="px-6 py-24 sm:py-32 lg:px-8">
      <header>
        <h1>Prymshare Terms and Conditions</h1>
    </header>

    <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By using Prymshare's web application, you agree to comply with and be bound by these terms and conditions. If you do
            not agree to these terms, please do not use Prymshare.</p>
    </section>

    <section>
        <h2>2. User Accounts</h2>
        <p>To use certain features of Prymshare, you may be required to create a user account. You are responsible for maintaining
            the confidentiality of your account credentials and for all activities that occur under your account.</p>
        <p>You agree to provide accurate, current, and complete information during the registration process and to update such
            information to keep it accurate, current, and complete.</p>
    </section>

    <section>
        <h2>3. User Content</h2>
        <p>By submitting content to Prymshare, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce,
            distribute, and display the content in connection with Prymshare's services.</p>
        <p>You retain ownership of any intellectual property rights in the content you submit.</p>
    </section>

    <section>
        <h2>4. Prohibited Conduct</h2>
        <p>While using Prymshare, you agree not to engage in any conduct that violates these terms, including but not limited to
            the following:</p>
        <ul>
            <li>Violating applicable laws or regulations</li>
            <li>Impersonating any person or entity</li>
            <li>Uploading malicious code or engaging in harmful activities</li>
            <li>Interfering with the proper functioning of Prymshare</li>
        </ul>
    </section>

    <section>
        <h2>5. Termination</h2>
        <p>We reserve the right to suspend or terminate your access to Prymshare at our sole discretion, with or without cause and
            with or without notice.</p>
    </section>

    <section>
        <h2>6. Disclaimer of Warranties</h2>
        <p>Prymshare is provided "as is" and "as available" without any warranties, express or implied. Your use of Prymshare is
            at your own risk.</p>
    </section>

    <section>
        <h2>7. Limitation of Liability</h2>
        <p>In no event shall Prymshare or its affiliates be liable for any indirect, incidental, special, consequential, or
            punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data,
            use, goodwill, or other intangible losses.</p>
    </section>

    <section>
        <h2>8. Changes to Terms and Conditions</h2>
        <p>We reserve the right to update or modify these terms and conditions at any time without prior notice. Your continued use
            of Prymshare after any changes indicates your acceptance of the modified terms.</p>
    </section>

    <section>
        <h2>9. Contact Us</h2>
        <p>If you have any questions about these terms and conditions, please contact us at <a href="mailto:legal@prymshare.co">legal@prymshare.co</a>.</p>
    </section>
    </main>
  );
}

export default Terms;
