import './App.css';
import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

// import '@ionic/react/css/core.css';
// import { Provider } from 'react-redux';
// import store from './store.tsx';
import Layout from './hocs/Layout.tsx';
import ProfileLayout from './hocs/ProfileLayout.tsx';
import NeutralLayout from './hocs/NeutrualLayout.tsx';
import PrivateRoute from './hocs/PrivateRoute.tsx';
import ProfileSettingsLayout from './hocs/ProfileSettingsLayout.tsx';
import Home from './containers/Home/home.tsx';
import Community from './containers/Home/community.tsx';
import Features from './containers/Home/features.tsx';
import Login from './containers/Login/login.tsx';
import ResetPassword from './containers/Login/resetPassword.tsx';
import ResetPasswordConfirm from './containers/Login/resetPwordConfirm.tsx';
import Signup from './containers/Signup/signup.tsx';
import Activate from './containers/Signup/activate.tsx';
import CheckMail from './containers/Signup/checkMail.tsx';
import PrivacyPolicy from './containers/Policies/PrivacyPolicy.tsx';
import RefundPolicy from './containers/Policies/RefundPolicy.tsx';
import Terms from './containers/Policies/Terms.tsx';
import Profile from './containers/BioPage/profile.tsx';
import VerifyPayment from './containers/BioPage/verifyPayments.tsx';
import ProfileDash from './containers/ProfileDashboard/profiledash.tsx';
import Customize from './containers/ProfileDashboard/customize.tsx';
import Complete from './containers/ProfileDashboard/completeProfile.tsx';
import AddSocials from './containers/ProfileDashboard/addSocials.tsx';
import NoMatch from './containers/NoMatch/nomatch.tsx';
import Analytics from './containers/ProfileDashboard/analytics.tsx';
import Feedback from './containers/ProfileDashboard/feedbacks.tsx';
import AllFeedback from './containers/ProfileDashboard/allfeedback.tsx';
import Account from './containers/ProfileDashboard/myAccount.tsx';
import PaymentSettings from './containers/ProfileDashboard/paymentSettings.tsx';
import Billing from "./containers/ProfileDashboard/Billing.tsx"

// import {  setupIonicReact } from '@ionic/react';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="community" element={<Community />} />
        <Route path="/features" element={<Features />} />
        <Route path="*" element={<NoMatch />} />
        <Route path="/feedback" element={<AllFeedback />} />
        <Route path='/payments/verify_payment' element={<VerifyPayment />} /> 
      </Route>
      <Route path="/" element={<NeutralLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/complete-profile" element={<PrivateRoute component={Complete} />} />
        <Route path="/add-socials" element={<AddSocials />} />
        <Route path="/check-mail" element={<CheckMail />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/activate/:uid/:token' element={<Activate />} />
        <Route path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />
      </Route>
      <Route path="/" element={<ProfileLayout />}>
        <Route path='/:id' element={<Profile />} />
        
      </Route>
      <Route path="/" element={<ProfileSettingsLayout />}>
        <Route path='/me/appearance/' element={<PrivateRoute component={ProfileDash} />} />
        <Route path='/me/customize' element={<PrivateRoute component={Customize} />} />
        {/* <Route path='/me/analytics' element={<PrivateRoute component={Analytics} />} /> */}
        <Route path='/me/analytics'  element={<Analytics /> }/> 
        <Route path='/me/settings' element={<PrivateRoute component={ProfileDash} />} />
        <Route path='/me/feedback' element={<PrivateRoute component={Feedback} />} />
        <Route path='/me/account' element={<PrivateRoute component={Account} />} />
         {/* <Route path='/me/payment'  element={<PaymentSettings /> }/>  */}
       <Route path='/me/payment' element={<PrivateRoute component={PaymentSettings} />} /> 
      </Route>
      
      
    </Routes>
  );
}

export default App;
