import React, { useState, useEffect } from 'react';
import { redirect, useNavigate, Link, useParams } from "react-router-dom";
import { verify, handleGoogleLogin } from '../../state/actions/auth';


import Loginpic from '../../images/login-pic.png';
import Logo from '../../images/new_logo.png';
import LogoM from '../../images/logo-main.png';
import PostBox from '../../images/postbox.png';
import GoogleLogo from '../../images/google.png';



const CheckMail = () => {
    let navigate = useNavigate();
    const { uid, token } = useParams();

    const [loading, setLoading] = useState(false);

    const verify_account = e => {
        setLoading(true);
        verify(uid, token, navigate, setLoading);
    };


    // useEffect(() => {
    //     if (verified) {
    //         navigate('/login');
    //     }
    // }, [verified]);

    return (

        <div className="signup">
            <div className="signup-section2">
                <div className="flex justify-between items-center mt-3">
                    <img className='logo hidden lg:flex' src={Logo} />
                    <img className='logo flex lg:hidden' style={{width: '35px'}} src={LogoM} />
                    <p className="bg-[#EEF1E8] text-[#505449] p-3 py-2 text-xs rounded-full">Already a Prymer? <Link to="/login" className='text-[#F84F94]'>Sign in</Link></p>
                </div>
  
                <div className="signup-texts">
                    <h1 className='font-black text-[#121B00] tracking-tight text-center'>CHECK YOUR MAILBOX</h1>
                    <p className='text-xs text-[#6F7466] text-center'>We sent you a mail with a link to activate your account!</p>
                </div>
                
                <div className='mt-9'>
                    <img className='flex m-auto' style={{width: '240px'}} src={PostBox} alt="Mail box" />
                </div>     
            </div>
      </div>
    );
};

export default CheckMail;
