
function RefundPolicy() {
    return (
      <main className="px-6 py-24 sm:py-32 lg:px-8">
        <div className="container">
            <h1><strong>Refund Policy for Creators</strong></h1>
            <p>At Prymshare, we understand the importance of providing a seamless and satisfying experience for creators and their customers. This refund policy outlines the guidelines and procedures for creators who wish to issue refunds to their customers for purchases made through Prymshare platform.</p>

            <strong>Refund Eligibility</strong>
            <ul>
                <li><strong>Creator Responsibility:</strong> Creators are responsible for managing refunds and returns for their products or services sold through Prymshare platform. Prymshare facilitates the refund process but does not directly issue refunds to customers on behalf of creators.</li>
                <li><strong>Refund Requests:</strong> Customers must submit refund requests directly to the creator from whom they made the purchase. Creators are responsible for reviewing and processing refund requests in accordance with their own refund policies and procedures.</li>
            </ul>

            <strong>Refund Process</strong>
            <ul>
                <li><strong>Customer Refund Requests:</strong> When a customer submits a refund request, the creator must review the request and determine its eligibility based on their own refund policy.</li>
                <li><strong>Refund Authorization:</strong> If the refund request is approved by the creator, they must initiate the refund process through Prymshare platform. Creators can access the necessary tools and features on Prymshare to process refunds for their customers.</li>
                <li><strong>Refund Issuance:</strong> Once the refund is authorized by the creator, Prymshare will facilitate the refund transaction and process the refund amount to the customer's original method of payment.</li>
            </ul>

            

            <strong>Contact Us</strong>
            <p>If you have any questions or need assistance with issuing refunds to your customers, please contact Prymshare's customer support team at <a href="mailto:prymshare.info@gmail.com">prymshare.info@gmai.com</a>. We're here to help!</p>
            </div>
      </main>
    );
  }
  
  export default RefundPolicy;
  