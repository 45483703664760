import { useEffect, useState, Fragment, useRef } from 'react';
import {Link, useParams} from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import Axios from 'axios';

import './intro.css';
import Frame from './pictureframes.tsx';
import FrameDemo from './pictureframes.tsx';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { loggedcreatordata, creatorpagedata, loggeddname, loggeduname } from "../../state/atoms/Creator";

import Tiktok from '../../images/tiktok.png';
import Instagram from '../../images/instagram.png';
import Youtube from '../../images/youtube.png';
import Linkedin from '../../images/linkedin1.png';
import Facebook from '../../images/facebook.png';
import Twitter from '../../images/twitterr.png';


import Vimeo from '../../images/vimeolink.png';
import Banner from '../../images/bannerpic.png';
import ProfilePic from '../../images/profilepic.png';
import GreenArrow from '../../images/greenarrow.png';

import { Helmet } from 'react-helmet';



function Intro({ username }) {
  const { id } = useParams();
  const [socials, setSocials] = useState([]);
  const [loading, setLoading] = useState(true);

  const [creator, setCreator] = useState([]);
  const [loadingCreator, setLoadingCreator] = useState(true);

  const [loggedCreatorData, setLoggedCreatorData] = useRecoilState(creatorpagedata);

  // const socialsId = socialsData.id;

  let socialMediaLinks = loggedCreatorData.socials;
  let theCreator = loggedCreatorData.creator;
  // let theCreatorLogo = `${theCreator.logo}`

  useEffect(() => {
    let socialMediaLinks = loggedCreatorData.socials;
    let theCreator = loggedCreatorData.creator;
}, [loggedCreatorData]);



  return (
    <>
    <Helmet>
      <title>{theCreator.display_name == undefined || theCreator.display_name == null ? 'Check out My Page' : theCreator.display_name}</title>
      <meta
          name='description'
          content={theCreator.creator_desc == undefined || theCreator.display_name == null ? '' : theCreator.creator_desc}
      />
      <link rel="icon" type="image/x-icon" href={theCreator.logo} />
    </Helmet>

    <div className="header-block">
      <div className="pro-pic">
          <img src={theCreator.logo} className="profilepic" />
      </div>
      <div className="intro">
        <div className="top">
          <div className="desc">
            <div className="name-title">
              <div className="occupation oc-desktop">
                <h2>{theCreator.category}</h2>
              </div>
              <h1 className="">{theCreator.display_name}</h1>
              
            </div>
            <p>{theCreator.creator_desc}</p>
          </div>
          
        </div>

        <div className="mid">
          <div className="socials">
            {socialMediaLinks != null && 
              <>
                {socialMediaLinks.youtube != '' && socialMediaLinks.youtube != null ? <a href={socialMediaLinks.youtube} target="_blank"><img src={Youtube} /></a> : <></>}
                {socialMediaLinks.instagram != '' && socialMediaLinks.instagram != null ? <a href={socialMediaLinks.instagram} target="_blank"><img src={Instagram} /></a> : <></>}
                {socialMediaLinks.facebook != '' && socialMediaLinks.facebook != null ? <a href={socialMediaLinks.facebook} target="_blank"><img src={Facebook} /></a> : <></>}
                {socialMediaLinks.tiktok != '' && socialMediaLinks.tiktok != null ? <a href={socialMediaLinks.tiktok} target="_blank"><img src={Tiktok} /></a> : <></>}
                {socialMediaLinks.twitter != '' && socialMediaLinks.twitter != null ? <a href={socialMediaLinks.twitter} target="_blank"><img src={Twitter} /></a> : <></>}
                {socialMediaLinks.linkedin != '' && socialMediaLinks.linkedin != null ? <a href={socialMediaLinks.linkedin} target="_blank"><img src={Linkedin} /></a> : <></>}
              </>
            }
            
          </div>

          {/* <div className="occupation oc-mobile">
            <h2>{theCreator.category}</h2>
          </div> */}

          {/*<div className="tags">
            <p>Title One</p>
            <p>Title Two</p>
            <p className="lastman">Title Three</p>
          </div>*/}
        </div>
        
      </div>
    </div>
    </>
  );
}

export default Intro;
