import { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

import Bio from '../../images/bio.png';
import Record from '../../images/record.png';
import Butterfly from '../../images/butterfly.png';
import Demopage from '../../images/demopagecut.png';

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';


function Second () {
  

  return (
    <div className="p-4 pt-28" id="linkinbio">
        <div className='flex flex-col max-w-6xl m-auto'>
            <div className='flex flex-col mb-9'>
                <AnimationOnScroll animateIn="slideInLeft" duration="0.5"><h1 className='text-[#BDC2B1] p-2 pb-0 text-4xl lg:text-5xl font-black text-center tracking-tight mb-4'>BEYOND A LINK-<br className='lg:hidden' />IN-BIO.<br className='hidden lg:block' /> <span className='text-[#121B00]'>CURATE<br className='lg:hidden' /> THE RIGHT WAY!</span></h1></AnimationOnScroll>
                <p className='text-xs lg:text-base text-center text-[#797E6F]'>Connect your Instagram, YouTube, TikTok, website, streams, events, videos and more,<br className='hidden lg:block' /> the right way as a content creator</p>
            </div>

            <Link to='/register' className='bg-[#121B00] flex m-auto mb-16 text-white text-sm hover:text-black hover:bg-white hover:border-2 hover:border-[#121B00] px-11 py-3.5 rounded-full'>Get started - It's free</Link>

            <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='p-9 px-8 pb-0 lg:p-10 lg:pb-0 lg:pl-16 bg-[#121B00] flex flex-col lg:flex-row rounded-4xl justify-between items-center'>
                <div className='flex flex-col items-center lg:items-start lg:w-4/12'>
                    <h1 className='text-[#D2D8C7] text-4xl lg:text-5xl text-center lg:text-start font-black mb-1'>THE ONLY PAGE<br />YOU'LL EVER NEED</h1>
                    <p className='text-sm text-[#B3B9A7] text-center lg:text-start mb-9'>Customize your page with ease and drive your engagement to the next level. We made it so you can build your own way.</p>
                    <a href='#' className='flex bg-[#E5E5E5] mb-14 lg:mb-0 text-[#121B00] text-sm font-semibold  hover:text-black hover:bg-white hover:border-2 hover:border-primaryy-600 px-16 py-3 rounded-full'>Learn More</a>
                </div>
                <div className='flex justify-center w-full lg:w-8/12 relative'>
                    <img className='absolute w-5/12 lg:w-3/12 widget-1 animate-zoom' src={Record} alt="share-widget" />
                    <img className='w-8/12 lg:w-6/12 lg:h-7/12' src={Demopage} alt="creator-widget" />
                    <img className='absolute w-5/12 lg:w-4/12 widget-2 animate-bounce' src={Butterfly} alt="profile-widget" />
                </div>
            </AnimationOnScroll>
        </div>
      
    </div>
  );
}

export default Second;
