
function PrivacyPolicy() {
  return (
    <main className="px-6 py-24 sm:py-32 lg:px-8">
        <header>
        <h1>Prymshare Privacy Policy</h1>
    </header>

    <section>
        <h2>1. Introduction</h2>
        <p>Welcome to Prymshare! This Privacy Policy describes how we collect, use, and disclose your personal information when
            you use our web application.</p>
    </section>

    <section>
        <h2>2. Information We Collect</h2>
        <p>We collect information you provide directly to us, such as your name, email address, and other information you choose
            to share.</p>
        <p>We may also automatically collect certain information when you use Prymshare, including your IP address, device
            information, and usage data.</p>
    </section>

    <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
            <li>Provide and improve our services</li>
            <li>Send you updates and notifications</li>
            <li>Personalize your experience on Prymshare</li>
            <li>Respond to your inquiries and provide support</li>
        </ul>
    </section>

    <section>
        <h2>4. Sharing of Your Information</h2>
        <p>We may share your personal information with third parties only in the ways that are described in this Privacy Policy.</p>
        <p>We will not sell, rent, or share your personal information with third parties for their promotional purposes.</p>
    </section>

    <section>
        <h2>5. Your Choices</h2>
        <p>You may choose not to provide certain personal information, but this may limit your ability to use certain features of
            Prymshare.</p>
        <p>You can update your account information and preferences at any time.</p>
    </section>

    <section>
        <h2>6. Security</h2>
        <p>We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized
            access.</p>
    </section>

    <section>
        <h2>7. Changes to this Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
            Policy on this page.</p>
    </section>

    <section>
        <h2>8. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@prymshare.co">support@prymshare.co</a>.</p>
    </section>
    </main>
  );
}

export default PrivacyPolicy;
