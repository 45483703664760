import axios, { all } from 'axios';
import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { authenticated } from "./Authenticated"
import { authenticated, access, refresh, userid, firstname, lastname, email } from "../../state/atoms/Authenticated";
import { loggedcreator } from "../../state/atoms/Creator";
// import Home from '../containers/Home/home.tsx'
import {
  Link
} from "react-router-dom";
import { redirect, useNavigate } from "react-router-dom";

import { addCreatorSocials } from '../../state/actions/creator';

import Logo from '../../images/logo-main.png';
import { isValid } from 'date-fns';


function AddSocials() {
	let navigate = useNavigate();

	const [authState, setAuthState] = useRecoilState(authenticated);
	const [accessState, setAccessState] = useRecoilState(access);
	const [refreshState, setRefreshState] = useRecoilState(refresh);

	const isAuth = useRecoilValue(authenticated);
    const isCreatorAlready = useRecoilValue(loggedcreator);

    const firstName = useRecoilValue(firstname);
    const lastName = useRecoilValue(lastname);
	// const [formData, setFormData] = useState({
 //        email: '',
 //        password: '' 
 //  });


    // const [website, setWebsite] = useState(null);
    const [youtube, setYoutube] = useState('');
    const [spotify, setSpotify] = useState('');
    const [vimeo, setVimeo] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [linkedin, setLinkedin] = useState('');

    const [links, setLinks] = useState({
        youtube: '',
        tiktok: '',
        linkedin: '',
        spotify: '',
        facebook: '',
        instagram: '',
        twitter: '',
        vimeo: '',
      });

      const [errors, setErrors] = useState({});
      const [submittable, setSubmittable] = useState(false);

      const [isValid, setIsValid] = useState(false);

      const handleChange = (event) => {
        const { name, value } = event.target;
        setLinks({
          ...links,
          [name]: value
        });
    
        // Validate URL only if value is not empty
        if (value.trim() !== '') {
          validateURL(value, name);
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            [name]: null // Reset error message for this field
          }));
          setIsValid(true);
        }
      };
    
      const validateURL = (url, fieldName) => {
        // Regular expression for validating URLs
        const urlRegex = /^(ftp|http|https):\/\/[^ "]*$/;
        const isHereValid = urlRegex.test(url)
        setIsValid(urlRegex.test(url));
        setErrors(prevErrors => ({
          ...prevErrors,
          [fieldName]: isHereValid ? null : 'This link is invalid. All fields should begin with "https://"'
        }));
      };

      
    

     const [loading, setLoading] = useState(false);

     const [allFieldsValid, setAllFieldsValid] = useState(false);


//   useEffect(() => {
//   	if (isCreatorAlready) {
// 			return navigate("/me/profile/");
//   	}
//   }, [isCreatorAlready]);



    useEffect(() => {
        // Check if at least one input field is filled
        const isAnyFieldFilled = Object.values(links).some(value => value.trim() !== '');
    
        // console.log(isValid)

        if(isValid) {
            setSubmittable(true)
          } else {
            setSubmittable(false)
          }
        
      }, [links, isValid]);


  const onSubmit = e => {
    e.preventDefault();
    
    setLoading(true);
    // console.log(selectedOption.value);
    let formData = new FormData();
    formData.append('youtube', links.youtube);
    formData.append('spotify', links.spotify);
    formData.append('twitter', links.twitter);
    formData.append('facebook', links.facebook);
    formData.append('instagram', links.instagram);
    formData.append('tiktok', links.tiktok);
    formData.append('linkedin', links.linkedin);

    addCreatorSocials(formData, navigate, setLoading);
  };

  return (
    <main className='main-complete'>
        <div className='complete-nav'>
            <img src={Logo} />
        </div>
        <div className="complete-profile">
            <div className='step'>
                <h1>You are one step closer! Add your Socials</h1>
                <p>Fill in the links that apply to you.</p>
            </div>
            
            <form className="" action="#" onSubmit={e => onSubmit(e)}>
                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    YouTube Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="youtube" value={links.youtube} onChange={handleChange} />
                    </div>
                    {errors.youtube && <p className='uname-val'>{errors.youtube}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Instagram Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="instagram" value={links.instagram} onChange={handleChange} />
                    </div>
                    {errors.instagram && <p className='uname-val'>{errors.instagram}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Twitter(X) Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="twitter" value={links.twitter} onChange={handleChange} />
                    </div>
                    {errors.twitter && <p className='uname-val'>{errors.twitter}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Tiktok Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="tiktok" value={links.tiktok} onChange={handleChange} />
                    </div>
                    {errors.tiktok && <p className='uname-val'>{errors.tiktok}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Facebook Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="facebook" value={links.facebook} onChange={handleChange} />
                    </div>
                    {errors.facebook && <p className='uname-val'>{errors.facebook}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Linkedin Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="linkedin" value={links.linkedin} onChange={handleChange} />
                    </div>
                    {errors.linkedin && <p className='uname-val'>{errors.linkedin}</p>}
                </div>

                {/* <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Spotify Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="spotify" value={links.spotify} onChange={handleChange} />
                    </div>
                    {errors.spotify && <p className='uname-val'>{errors.spotify}</p>}
                </div> */}

                
                <div className='divider-profile'>
                    <div></div><div className=''></div>
                </div>
                

                <div className={loading || !submittable ? 'submit-com-dis' : 'submit-comp'}>
                    <button
                        type="submit"
                        disabled={loading || !submittable}
                        className="complete-button"
                    >
                    Continue
                    </button>
                </div>
            </form>
        </div>
    </main>
  	
  );
}

export default AddSocials;
