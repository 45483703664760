import Addd from '../../images/add-circle.png';
import Plus from '../../images/plus-circle.png';
import Pro from '../../images/Tag.png';
import Premium from '../../images/premiums.png';
import Gift from '../../images/giftbox.png';
import Xprice from '../../images/x-price.png';
import { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import {Link, useParams, useNavigate } from "react-router-dom";

import imageCompression from 'browser-image-compression';

import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TECollapse,
} from "tw-elements-react";

import { creatorlinks } from "../../state/atoms/Creator";

import { addCreatorLinks } from '../../state/actions/creator';

import PaywallModal from "./linkButtons/paywallModal.tsx";
import GiftModal from "./linkButtons/giftModal.tsx";
import { setFlagsFromString } from 'v8';




function AddBlockBtn({ showGift, setShowGift, availableBlocks, handleAddBlock, showModal, setShowModal }) {

    const [linktitle, setLinktitle] = useState('')
    const [url, setUrl] = useState('')
    const [linkType, setLinkType] = useState('normal')
    const [price, setPrice] = useState(0)

    const [loading, setLoading] = useState(false)
    const [loadingPrice, setLoadingPrice] = useState(false)
    const [submittable, setSubmittable] = useState('')


    const [editLogo, setEditLogo] = useState(null);
    const [prologo, setPrologo] = useState(null);
	const [prologoUrl, setPrologoUrl] = useState();
    const [progress, setProgress] = useState(0);
    const [creatorLinks, setCreatorLinks] = useRecoilState(creatorlinks);

    const hiddenFileInput = useRef(null);

	const handleonLogoClick = event => {
		hiddenFileInput.current.click();
	};

    const handleDeleteLogo = () => {
        setPrologo(null);
        setEditLogo(null);
        setPrologoUrl('');
    }

    const onChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
          const options = {
            maxSizeMB: 1, // Max size in MB
            maxWidthOrHeight: 1920, // Max width or height
            useWebWorker: true, // Use web workers for better performance
            onProgress: (progress) => {
              setProgress(Math.round(progress));
            },
          };
    
          try {
            const compressedBlob = await imageCompression(file, options);
    
            // Convert the compressed Blob back to a File object
            const compressedFile = new File(
              [compressedBlob],
              file.name,
              { type: compressedBlob.type }
            );
    
            setPrologo(compressedFile);
            setPrologoUrl(URL.createObjectURL(compressedFile));
            console.log(compressedFile);
            setProgress(0); // Reset progress
          } catch (error) {
            alert('Error compressing the image; please refresh the page and try again!');
            setProgress(0); // Reset progress on error
          }
        }
      };

      const [showPaywallModal, setShowPaywallModal] = useState(false)
      
    
      const addPaywall = () => {
        setLinkType('paywalled')

        setShowPaywallModal(true);
      }

      const addGift = () => {
        setLinkType('tipping')

        setShowGift(true);
        setShowModal(false);
      }

      const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        // console.log(selectedOption.value);
        let formData = new FormData();
        if (prologo != null) {
          formData.append('thumb', prologo);
        } else if (editLogo == null) {
          formData.append('thumb', '');
        }
        formData.append('title', linktitle);
        // formData.append('creator_username', username);
        formData.append('url', url);
        formData.append('type', linkType);
        formData.append('price', price);
       
    
        addCreatorLinks(formData, setShowModal, setCreatorLinks, setLoading, setPrologo, setPrologoUrl, setLinktitle, setUrl);
      };

    
    
    return (
        <div className="add-blocks">
            <div className="add-block-btn" onClick={() => setShowModal(true)}>
                <img src={Plus} />
                <p>Add a link</p>
            </div>


            {/* MODAL FOR ADDING BLOCKS */}
            <TEModal show={showModal} setShow={setShowModal} scrollable>
            <TEModalDialog className={showPaywallModal || showGift ? '!p-3 !blur-sm' : '!p-3'}>
                <TEModalContent className=''>
                <TEModalHeader>
                    {/* <!--Modal title--> */}
                    <h5 className="text-medium font-bold leading-normal text-neutral-800 dark:text-neutral-200">
                    Add a link
                    </h5>
                    {/* <!--Close button--> */}
                    <button
                    type="button"
                    className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                    aria-label="Close"
                    >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                    </button>
                </TEModalHeader>
                {/* <!--Modal body--> */}
                <TEModalBody>                
                    <form className="" action="#" onSubmit={e => onSubmit(e)}>
                    <div className="upload-logo">
                        <div className="edit-pic">
                          <div className='flex w-full flex-col'>
                            <p className='font-medium' style={{fontSize: '80%'}}>Insert Thumbnail</p>
                            <img src={prologoUrl ? prologoUrl : editLogo} className="linkthumb" />
                          </div>
                        </div>

                        <div onClick={handleonLogoClick} className="selectbtn-img" style={{cursor: 'pointer'}}>
                        <p>Select</p>
                        </div>
                        <div onClick={handleDeleteLogo} className="deletebtn-img" style={{cursor: 'pointer'}}>
                        <p>Delete</p>
                        </div>
                    
                        <input
                            className='form-control'
                            ref={hiddenFileInput}
                            style={{display:'none'}}
                            id="upload-profile"
                            type='file'
                            accept='image/png, image/jpeg, image/gif'
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                        <div>
                            <label htmlFor="email" className="!font-bold">
                            Link Title
                            </label>
                            
                            <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                value={linktitle}
                                onChange={e => setLinktitle(e.target.value)}
                                className=""
                            />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor="email" className="!font-bold">
                            Enter URL
                            </label>
                            
                            <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                value={url}
                                onChange={e => setUrl(e.target.value)}
                                className=""
                            />
                            </div>
                        </div>
                        
                        {price > 0 ? 
                            <div className='w-full flex justify-between items-center my-5 mb-8 p-4 py-5 rounded-2xl bg-[#F84F94]'>
                                <p className='!text-base font-bold text-white'>NGN {price}</p>
                                <img onClick={() => setPrice(0)} src={Xprice} className='w-[24px] cursor-pointer' />
                            </div> : <></>
                        }
                        <div className='submit-comp'>
                        {loading ? 
                            <div
                                className="flex ml-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                <span
                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                >Loading...</span>
                            </div> :
                            <button
                                type="submit"
                                disabled={loading}
                                className="complete-button"
                            >
                            Continue
                            </button>
                        }
                            {/* <button
                                type="submit"
                                disabled={loadingHeader || !submittable}
                                className="complete-button"
                            >
                            Continue
                            </button> */}
                        </div>
                    </form>

                    {price > 0 ?
                        <></> :

                        <>
                            <hr className='w-full text-[#D2D8C7] mt-8 mb-8' />

                            <div className='flex flex-col'>
                                {/* <p className='font-bold text-sm '>Do more with your links <span className='ml-2 py-1 px-2 text-xs font-black border-[#00D37F] border-2 rounded-full '>PRO</span></p> */}
                                <p className='font-bold text-sm '>Do more with your links</p>

                                <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addPaywall}>
                                    <img src={Premium} className='w-[40px] mr-4 ' />
                                    <div className='flex flex-col '>
                                        <h2 className='font-bold text-sm'>Add a paywall</h2>
                                        <p className='font-normal text-xs'>Display and monetize your content</p>
                                    </div>
                                </div>
                                <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addGift}>
                                    <img src={Gift} className='w-[40px] mr-4 ' />
                                    <div className='flex flex-col '>
                                        <h2 className='font-bold text-sm'>Get Tipped</h2>
                                        <p className='font-normal text-xs'>Get your fans to show you love</p>
                                    </div>
                                </div>
                            </div>  
                        </>
                    }

                    

                </TEModalBody>
                <TEModalFooter>
                    <TERipple rippleColor="light">
                    {/* <button
                        type="button"
                        className="inline-block rounded bg-primaryy-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primaryy-700 transition duration-150 ease-in-out hover:bg-primaryy-accent-100 focus:bg-primaryy-accent-100 focus:outline-none focus:ring-0 active:bg-primaryy-accent-200"
                        onClick={() => setShowModal(false)}
                    >
                        Close
                    </button> */}
                    </TERipple>
                    
                </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
            </TEModal>

            {/* MODAL FOR PAYALL */}
            <PaywallModal showPaywallModal={showPaywallModal} setShowPaywallModal={setShowPaywallModal} loadingPrice={loadingPrice} setLoadingPrice={setLoadingPrice} price={price} setPrice={setPrice} />
            <GiftModal showGift={showGift} setShowGift={setShowGift} loadingPrice={loadingPrice} setLoadingPrice={setLoadingPrice} price={price} setPrice={setPrice} showModal={showModal} setShowModal={setShowModal} 
            linktitle={linktitle} linkType={linkType} url={url} prologo={prologo} editLogo={editLogo} loading={loading} setLoading={setLoading} setCreatorLinks={setCreatorLinks} setPrologo={setPrologo} setPrologoUrl={setPrologoUrl} setLinktitle={setLinktitle} setUrl={setUrl} />
        </div>
    );
  }
  
  export default AddBlockBtn;
  