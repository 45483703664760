import './signup.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { authenticated } from "./Authenticated"
import { authenticated } from "../../state/atoms/Authenticated";
import { access } from "../../state/atoms/Authenticated";
import { refresh } from "../../state/atoms/Authenticated";
import { loggedcreator } from "../../state/atoms/Creator";
// import Home from '../containers/Home/home.tsx'
import {
  Link
} from "react-router-dom";
import { redirect, useNavigate  } from "react-router-dom";

import { signUp, handleGoogleLogin } from '../../state/actions/auth';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';

import Loginpic from '../../images/login-pic.png';
import Logo from '../../images/new_logo.png';
import LogoM from '../../images/logo-main.png';
import GoogleLogo from '../../images/google.png';
// import GoogleLogin from "react-google-signup";


function Signup() {
  let navigate = useNavigate();

  const [authState, setAuthState] = useRecoilState(authenticated);
  const [accessState, setAccessState] = useRecoilState(access);
  const [refreshState, setRefreshState] = useRecoilState(refresh);
  
  const [googleLoading, setGoogleLoading] = useState(false);
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [re_password, setRe_Password] = useState('')

  const [username, setUsername] = useState('')
 
  const [userNameAvailable, setUserNameAvailable] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)
  const [pwordMessage, setPwordMessage] = useState(null)

  const isAuth = useRecoilValue(authenticated);
  const isCreatorAlready = useRecoilValue(loggedcreator);


  useEffect(() => {
    if (isAuth && isAuth!="loading") {
      if(isCreatorAlready && isCreatorAlready != 'loading') {
        return navigate('/me/appearance');
      } else if(!isCreatorAlready && isCreatorAlready != 'loading') {
        return navigate("/complete-profile");
      }
  	}
  }, [isAuth]);

  const onEmailChange = e => setEmail(e.target.value );
  const onPWordChange = e => setPassword(e.target.value);
  const onRPWordChange = (e) => {
    setRe_Password(e.target.value);
  }

  useEffect(() => {
    if (password === re_password) {
      setPwordMessage(null);
    } else {
      setPwordMessage("Passwords don't match!");
    }
  }, [password, re_password]);

  const continueWithGoogle = async () => {
    setGoogleLoading(true);
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/complete-profile`)
        window.location.replace(res.data.authorization_url);   
    } catch (err) {

    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => handleGoogleLogin(codeResponse, setAuthState, setAccessState, setRefreshState),
    flow: 'implicit',
  });

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
    const regex = /[^\w]/;
    if (regex.test(e.target.value)) {
        setErrorMessage('Username should not contain punctuation or space characters.');
        setUserNameAvailable(null);
    } else {
        setUserNameAvailable('loading');
        setErrorMessage(null);
        axios
        .get(`${process.env.REACT_APP_API_URL}/api/profile/check/${e.target.value}`)
        .then((res) => {
        //   console.log(res);
        setUserNameAvailable(res.data);
        })
        .catch((err) => {
        console.log("Error", err);
        });
    }
  }
  const onSubmit = e => {
    e.preventDefault();
    setLoading(true)
    // console.log('signup');
    signUp(email, password, re_password, setAuthState, setLoading, navigate);
  };

  return (
    <div className="signup">
      <div className="signup-section1">
        <img src={Loginpic} alt="Login to Prymshare"/>
      </div>

      <div className="signup-section2">
        <div className="flex justify-between items-center mt-3">
          <img className='logo hidden lg:flex' src={Logo} />
          <img className='logo flex lg:hidden' style={{width: '35px'}} src={LogoM} />
          <p className="bg-[#EEF1E8] text-[#505449] p-3 py-2 text-xs rounded-full">Already a Prymer? <Link to="/login" className='text-[#F84F94]'>Sign in</Link></p>
        </div>

        <div className="signup-texts">
          <h1 className='font-black text-[#121B00] tracking-tight'>JOIN PRYMSHARE</h1>
          <p className='text-xs text-[#6F7466]'>Sign up for free, and monetize fast and free</p>
        </div>
        

        <form className="" action="#" onSubmit={e => onSubmit(e)}>
          <div>
          <div className="username-input">
              <div className="username-child">
                  <p className="pre-input">
                  prymshare.co/
                  </p>
                  <p style={{width: '100%'}}><input className="input-onboard" name='username' type='text' value={username} style={{padding: 0}} placeholder="username" onChange={onUsernameChange}
                  /></p>
              </div>
              {errorMessage != null && <p className='uname-val'>{errorMessage}</p>}

              {userNameAvailable && username != '' ? <p style={{color: 'green', fontSize:'70%'}}>Username is available</p> : <></>}
              {!userNameAvailable && username != '' ?  <p style={{color: 'red', fontSize:'70%'}}>Username is not available</p> : <></>}
              {userNameAvailable === 'loading' && username != '' ?  <p style={{color: 'green', fontSize:'70%'}}>Checking username availability...</p> : <></>}

          </div>

          <div className="mt-4">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder='Email address'
                required
                value={email}
                onChange={e => onEmailChange(e)}
                className=""
              />
            </div>
          </div>

          <div>
            <div className="mt-4">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder='Password'
                value={password}
                onChange={e => onPWordChange(e)}
                minLength='6'
                required
                className=""
              />
            </div>

            <div className="mt-4 mb-4">
              <input
                id="re_password"
                name="re_password"
                type="password"
                autoComplete="current-password"
                placeholder='Confirm Password'
                value={re_password}
                onChange={e => onRPWordChange(e)}
                minLength='6'
                required
                className=""
              />
            </div>
            {pwordMessage != null && <p className='uname-val'>{pwordMessage}</p>}
          </div>

          <p className="signup-discla mb-4 text-xs">By creating an account, you agree to Prymshare's <a href="https://prymshare.co/terms-conditions" className='text-[#F84F94]'>Terms of Service. </a>You  may receive offers, news and updates from us.</p>

          <div className='mt-9'>
            <button
              type="submit"
              className="flex w-full justify-center rounded-full bg-[#121B00] px-3 py-3 text-sm leading-6 text-white shadow-sm hover:text-black hover:bg-white hover:border-2 hover:border-[#121B00]"
            >
              {loading ? <div
                          className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          role="status">
                          <span
                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                          >Loading...</span>
                        </div> : <p>Create account</p>
                      }
            </button>
          </div>
        </form>

        <div className='flex justify-between items-center mt-10 '><hr className='w-5/12 text-[#D2D8C7]'/> or <hr className='w-5/12 text-[#D2D8C7]' /></div>

        <div className="google-btn" onClick={continueWithGoogle}>
          <img src={GoogleLogo} />
          <p>Sign up with Google</p>
        </div>

      </div>
    </div>
  );
}

export default Signup;
