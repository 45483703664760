import React from 'react';
import { useRecoilValue } from 'recoil';
import { alertState } from '../state/atoms/Alerts';
import './components.css';

const Alert = () => {
  const alert = useRecoilValue(alertState);

  if (!alert.message) return null;

  return (
    <div className={`alert ${alert.type}`}>
      {alert.message}
    </div>
  );
};

export default Alert;
