import { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

import Pic1 from '../../images/pic1.png';
import Pic2 from '../../images/pic2.png';
import Pic3 from '../../images/pic3.png';
import Pic4 from '../../images/pic4.png';
import Pic5 from '../../images/pic5.png';
import Pic6 from '../../images/pic6.png';
import Pic7 from '../../images/pic7.png';
import Pic8 from '../../images/pic8.png';
import Pic9 from '../../images/pic9.png';
import Pic10 from '../../images/pic10.png';
import Logo from '../../images/logo-main.png';

import { register } from 'swiper/element/bundle';

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

register();

function Community () {
  const [creator, setCreator] = useState('thah_emiola');

  return (
    <div className='p-4 pt-7'>
      <div className="flex flex-col max-w-6xl m-auto p-5 pt-56 pb-16 lg:p-14 lg:pt-32 lg:pb-32 bgd-wave rounded-4xl">
        <div className="">
          <h1 className='text-primaryy-50 p-0 pb-0 text-5xl lg:text-6xl font-black tracking-tight mb-4'>Flex your online
          <br />brand like a pro <br /> with <span className='text-primaryy-400'>Prymshare</span> </h1>
        </div>
        <div className='flex flex-col lg:flex-row mt-6 items-center'>
            <div className='bg-transparent-button flex justify-between w-full lg:w-4/12 lg:width-creator p-3 pt-2 pb-2 lg:mr-5 items-center border-2 border-neutral-200 rounded-full'>
                <h1 className='w-8/12 text-neutral-50 text-2xl font-extrabold'>share.co/{creator}</h1>
                <img style={{width: '12%'}} className='' src={Logo} alt='Prymshare logo'/>
            </div>
            
            <AnimationOnScroll animateIn="bounceIn" duration="3" className='flex flex-wrap lg:flex-nowrap justify-between items-center mt-3 w-full lg:w-4/12 lg:mt-0'>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('theah_emiola')} src={Pic1} alt='Prym creator'/>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('Jayden')} onClick={() => setCreator('Jayden')} src={Pic2} alt='Prym creator'/>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('Genz_Hub')} onClick={() => setCreator('Genz_Hub')} src={Pic3} alt='Prym creator'/>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('Susan')} onClick={() => setCreator('Susan')} src={Pic4} alt='Prym creator'/>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('the_tomi')} onClick={() => setCreator('the_tomi')} src={Pic5} alt='Prym creator'/>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('Oluwaseyi')} onClick={() => setCreator('Oluwaseyi')} src={Pic6} alt='Prym creator'/>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('Pinkie')} onClick={() => setCreator('Pinkie')} src={Pic7} alt='Prym creator'/>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('The_Tele')} onClick={() => setCreator('The_Tele')} src={Pic8} alt='Prym creator'/>
                <img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('IPDevs')} onClick={() => setCreator('IPdevs')} src={Pic9} alt='Prym creator'/>
                <img className='width-creator mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('_Morgan')} onClick={() => setCreator('_Morgan')} src={Pic10} alt='Prym creator'/>
            </AnimationOnScroll>

            {/*<swiper-container
              slides-per-view="4"
              navigation="true"
              pagination="true"

              className="lg:hidden"
            >
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('theah_emiola')} src={Pic1} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('Jayden')} src={Pic2} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('Genz_Hub')} src={Pic3} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('Susan')} src={Pic4} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('the_tomi')} src={Pic5} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('IPDevs')} src={Pic6} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('Mike')} src={Pic7} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('The_Tele')} src={Pic8} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator mr-1 mb-2 lg:mb-0 cursor-pointer' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('Oluwaseyi')} src={Pic9} alt='Prym creator'/></swiper-slide>
              <swiper-slide><img className='width-creator' onMouseOver={() => setCreator('theah_emiola')} onClick={() => setCreator('Babalola_Morgan')} src={Pic10} alt='Prym creator'/></swiper-slide>
  </swiper-container>*/}
        </div>
      </div>
    </div>
    
  );
}

export default Community;
