import {atom} from 'recoil'


export const iscreator = atom({
  key: 'iscreator', 
  default: false, 
});

export const theme = atom({
  key: 'theme',
  default: 'light',
});

export const creatorpagedata = atom({
  key: 'creatorpagedata', 
  default: {creator: {}, socials: {}, links: [], textblock: {}, giftblock: {}, paywall: [], vsection: {}, vposts: [], gsection: {}, gposts: [], iscreator: false}, 
});

// Is logged in user a creator?
export const loggedcreator = atom({
  key: 'loggedcreator', 
  default: 'loading', 
});

// Logged in creator's full data
export const loggedcreatordata = atom({
  key: 'loggedcreatordata', 
  default: {creator: {}, socials: {}, textblock: {}, paywall: [], vsection: {}, vposts: [], gsection: {}, gposts: [], giftblock: {}}, 
});

// Logged in creator Display Name
export const loggeddname = atom({
  key: 'loggeddname', 
  default: null, 
});

// Logged in creator user name
export const loggeduname = atom({
  key: 'loggeduname', 
  default: null, 
});

// Logged in creator bio picture
export const loggedpic = atom({
  key: 'loggedpic', 
  default: null, 
});

export const creatorlinks = atom({
  key: 'creatorlinks', 
  default: [], 
});

// Creator Page Structure
export const creatorps = atom({
  key: 'creatorps', 
  default: [], 
});

