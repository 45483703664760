import { useEffect, useState } from 'react';
// import Navbar from '../components/header/navbar';
import {
	Outlet,
	Link,
	useParams,
	useLocation

} from "react-router-dom";
import queryString from 'query-string';

import { useRecoilState, useRecoilValue } from 'recoil';
import { authenticated, access, refresh, userid, firstname, lastname, email } from "./../state/atoms/Authenticated";

import { googleAuthenticate, checkAuth, loadUser, logout } from './../state/actions/auth'

import { iscreator, theme, loggedcreator, loggedcreatordata, creatorpagedata, creatorps, loggeddname, loggeduname } from "./../state/atoms/Creator";
import { checkCreator, loadProfile, loadPageStructure } from './../state/actions/creator';

import Navbar from '../components/header/navbar.tsx';


const NeutralLayout = () => {
	const { id } = useParams();
	let location = useLocation();

	const [authState, setAuthState] = useRecoilState(authenticated);
	const [accessState, setAccessState] = useRecoilState(access);
	const [refreshState, setRefreshState] = useRecoilState(refresh);
	const [userId, setUserId] = useRecoilState(userid);
	const [firstName, setFirstName] = useRecoilState(firstname);
	const [lastName, setLastName] = useRecoilState(lastname);
	const [userEmail, setUserEmail] = useRecoilState(email);
	const isAuth = useRecoilValue(authenticated);

	const [creatorState, setCreatorState] = useRecoilState(iscreator);
	const [creatorPageData, setCreatorPageData] = useRecoilState(creatorpagedata);
	const [creatorTheme, setCreatorTheme] = useRecoilState(theme);
	const [loggedCreator, setLoggedCreator] = useRecoilState(loggedcreator);
	const [loggedCreatorData, setLoggedCreatorData] = useRecoilState(loggedcreatordata);
	const [loggedCreatorDname, setLoggedCreatorDname] = useRecoilState(loggeddname);
	const [loggedCreatorUname, setLoggedCreatorUname] = useRecoilState(loggeduname);
	const [creatorPS, setCreatorPS] = useRecoilState(creatorps);
	const isCreator = useRecoilValue(iscreator);
	const Theme = useRecoilValue(theme);

	useEffect(() => {
        const values = queryString.parse(location.search);
        const state = values.state ? values.state : null;
        const code = values.code ? values.code : null;

        console.log('State: ' + state);
        console.log('Code: ' + code);

        if (state && code) {
        	// console.log(code+'again');
            googleAuthenticate(state, code, setAuthState, setAccessState, setRefreshState);
        }

        // console.log(window.location.pathname);
        
    }, [location]);


	useEffect(() => {
		// const username = match.params.id;
		console.log('Checking verification for user!');
		loadUser(setUserId, setFirstName, setLastName, setUserEmail);
		loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
		loadPageStructure(id, setCreatorPS);
      	checkAuth(setAuthState);
      	if (id != undefined) {
      		checkCreator(setCreatorState, id, setCreatorTheme, setCreatorPageData);
      	}
      	
      	console.log(id);
  	}, [localStorage.getItem('access')]);


	return (
    <div>
		<Outlet />
    </div>
	);
}

export default NeutralLayout;