import { useEffect, useState, Fragment, useRef } from 'react';
import {Link, useParams} from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';

import './demo.css';
import Frame from '../../pictureframes.tsx';
// import FrameDemo from './pictureframes.tsx';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Tiktok from '../../../images/tiktok-m.png';
import Instagram from '../../../images/instagram-m.png';
import Youtube from '../../../images/youtube-m.png';
import Linkedin from '../../../images/linkedin-m.png';
import Facebook from '../../../images/facebook-m.png';
import Twitter from '../../../images/twitterx.png';



import ProfilePic from '../../../images/profilepic.png';
import GreenArrow from '../../../images/greenarrow.png';

import Delete from '../../../images/delete.png';
import Edit from '../../../images/edit.png';

import { Helmet } from 'react-helmet';


export const FrameDemo = () => {
  return (
    <div>
      <div className="pro-pic">
          <img src={ProfilePic} className="profilepic" />
        <div> </div>
      </div>
    </div>
  )
}


const IntroDemo = ({ creatorData, showModal1, setShowModal1 }) => {

  let socialMediaLinks = creatorData.socials;
  let theCreator = creatorData.creator;
  let theCreatorLogo = `${theCreator.logo}`

  useEffect(() => {
    let socialMediaLinks = creatorData.socials;
    let theCreator = creatorData.creator;
}, [creatorData]);

  return (
    <>
      <div className="pro-pic">
          <img src={theCreatorLogo} className="profilepic" />
      </div>
      <div className="intro">
        <div className="top">
          <div className="desc">
            <div className="name-title">
              <h1 className="">{theCreator.display_name}</h1>
              <div className="occupation">
                <p>{theCreator.category}</p>
              </div>
            </div>
            <p>{theCreator.creator_desc}</p>
          </div>
          
        </div>

        <div className="mid">
        <div className="socials">
            {socialMediaLinks != null && 
              <>
                {socialMediaLinks.youtube != '' && socialMediaLinks.youtube != null ? <a href={socialMediaLinks.youtube} target="_blank"><img src={Youtube} /></a> : <></>}
                {socialMediaLinks.instagram != '' && socialMediaLinks.instagram != null ? <a href={socialMediaLinks.instagram} target="_blank"><img src={Instagram} /></a> : <></>}
                {socialMediaLinks.facebook != '' && socialMediaLinks.facebook != null ? <a href={socialMediaLinks.facebook} target="_blank"><img src={Facebook} /></a> : <></>}
                {socialMediaLinks.tiktok != '' && socialMediaLinks.tiktok != null ? <a href={socialMediaLinks.tiktok} target="_blank"><img src={Tiktok} /></a> : <></>}
                {socialMediaLinks.twitter != '' && socialMediaLinks.twitter != null ? <a href={socialMediaLinks.twitter} target="_blank"><img src={Twitter} /></a> : <></>}
                {socialMediaLinks.linkedin != '' && socialMediaLinks.linkedin != null ? <a href={socialMediaLinks.linkedin} target="_blank"><img src={Linkedin} /></a> : <></>}
              </>
            }
            
          </div>

          {/*<div className="tags">
            <p>Title One</p>
            <p>Title Two</p>
            <p className="lastman">Title Three</p>
          </div>*/}
        </div>
        <div className='del-ed flex justify-center mt-7'>
          <img className='mr-7' src={Edit} onClick={() => setShowModal1(!showModal1)} />
        </div>
      </div>
    </>
  )
}


export default IntroDemo;