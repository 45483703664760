import React, { Component } from 'react';
import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
  Navigate,
  redirect,
  useParams,
} from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { authenticated } from "./../state/atoms/Authenticated";



const PrivateRoute = ({ component: Component, ...rest }) => {
  
  const isAuth = useRecoilValue(authenticated);
  return isAuth == true || isAuth == 'loading'  ? <Component {...rest} /> : <Navigate to="/login" replace />;
};


export default PrivateRoute;