import { useEffect, useState } from 'react';
import {Link, useParams} from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';

import './biopage.css';
import { useRecoilState, useRecoilValue } from 'recoil';

import { iscreator } from "../../state/atoms/Creator";

import MainPage from './mainpage.tsx';
import NotFound from './errorpage.tsx';


const Profile = () => {
  const { id } = useParams();

  // const [creatorState, setCreatorState] = useRecoilState(iscreator);
  const isCreator = useRecoilValue(iscreator);


  useEffect(() => {
    // console.log(id);
    // checkCreator(setCreatorState, id);
  }, []);

  return (
    <main>
        {/*{isCreator ?
          <MainPage /> 
          :
          <NotFound /> 
        }*/}
        <MainPage />
    </main>
  );
}

export default Profile;
