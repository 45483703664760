// VerifyPayment.js
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const VerifyPayment = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [verifying, setVerifying] = useState(true);

  useEffect(() => {
    const reference = searchParams.get('reference');
   

    const verifyPayment = async () => {
      setVerifying(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/subscriptions/verify_payment/?reference=${reference}`);
        if (response.data.status === 'success') {
          setSuccess(true);
          setVerifying(false);
        } else {
          setSuccess(false);
          setVerifying(false);
        }
      } catch (error) {
          setSuccess(false);
          setVerifying(false);
      }
    };

    if (reference) {
      verifyPayment();
    }
  }, [searchParams, navigate]);

  return (
    <div className='verify-pay'>
        {verifying ? <div className='verifying'>Verifying payment, please wait...</div> : 
            <>{success ? <div className='success-verify'>Payment Successful! Check your mail inbox shortly! <br /><span className='underline cursor-pointer' onClick={() => navigate(-1)}>Go back</span></div> : <div className='fail-verify'>Sorry, your payment failed. Try again. <br /><span className='underline cursor-pointer' onClick={() => navigate(-1)}>Go back</span></div>}</>
        }
    </div>
    
    
  );
};

export default VerifyPayment;
