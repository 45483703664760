import { useEffect, useState, Fragment, useRef } from 'react';
import {Link, useParams, useLocation} from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import Axios from 'axios';

import Select from 'react-select';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import check from '../../images/check.png'

import {  loggedcreator, loggedcreatordata, creatorpagedata, loggeddname, loggeduname  } from "../../state/atoms/Creator";
import { loadProfile } from '../../state/actions/creator';


function PaymentSettings() {
  
  const [loggedCreatorData, setLoggedCreatorData] = useRecoilState(loggedcreatordata);
	const [loggedCreatorDname, setLoggedCreatorDname] = useRecoilState(loggeddname);
	const [loggedCreatorUname, setLoggedCreatorUname] = useRecoilState(loggeduname);
  const [loggedCreator, setLoggedCreator] = useRecoilState(loggedcreator);
  const [banks, setBanks] = useState([])
  const [bankname, setBankname] = useState('');
  const [banknames, setBanknames] = useState([]);
  const [submittable, setSubmittable] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [acc_no, setAcc_no] = useState('');
  // const banknames = banks.map(a => ({ value: a.code, label: a.name }));

  const changeHandler = bankname => {
    setBankname(bankname);
  }

  useEffect(() => {
    const getBanks = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/subscriptions/paystack/banks`);
      setBanks(response.data.data);
    };
    getBanks();
    
  }, []);


  useEffect(() => {
    let bankDets = banks.map(a => ({ value: a.code, label: a.name }));
    setBanknames(bankDets);
    // console.log(bankDets);
    // console.log(bankname)
    // console.log(banknames)
  }, [banks]);


  useEffect(() => {
    if (acc_no != '' && bankname != '' && bankname != undefined && bankname != null) {
        setSubmittable(true);
        // console.log(submittable)
       } else {
        setSubmittable(false)
    }  
  }, [bankname, acc_no]);


  const saveSubAccount = () => {
    // console.log('saving')
    const config = {
        headers: {
            'Content-Type': "application/json",
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            // 'Accept': 'multipart/formdata'
        } 
      }; 
    axios.post(`${process.env.REACT_APP_API_URL}/api/subscriptions/create_subaccount/`, { bank_code: bankname.value, account_number: acc_no }, config)
      .then(response => {
        console.log('Subaccount Saved!', response.data);
        alert('Subaccount created successfully');
        setLoading(false);
        loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      })
      .catch(error => {
        alert('Error saving subaccount: Please refresh page or try again');
        setLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true);
    saveSubAccount();
  }

    return (
      <main className='bg-[#F0F0EF]'>
      <div className="grid min-h-full px-6 py-24 sm:py-32 lg:px-8 max-w-3xl m-auto">
        <div className='flex flex-col justify-center items-center my-2 '>
          <h1 className='font-black text-[#121B00] text-3xl'>PAYMENT SETTINGS</h1>
          <p className='text-xs text-[#6F7466]'>Setup your payout to start receiving payments.</p>
        </div>
        {/* <div className='prime-custom how-payments'>
            <h2 className='font-semibold'>How payments work?</h2>
            <p className='text-xs'>We collect your Nigerian bank account details, and create a subaccount for you.<br /><br />
            With this subaccount, you receive payments directly to your bank account between 1-2 days after payments have been made on your page.<br />
            <br />You can track your transactions <Link to='/me/account' className='underline font-normal'>here.</Link></p>
        </div> */}

        {loggedCreatorData.creator.subaccount_code != null && loggedCreatorData.creator.subaccount_code != '' && loggedCreatorData.creator.subaccount_code != undefined ?
          <div className='prime-custom bank-dets !text-[#6F7466]'>
              <h2 className='!font-bold text-sm'>Submitted account details:</h2>
              <h2 className='text-xs !font-bold'>Bank Name: <span className='font-normal'>{loggedCreatorData.creator.bank_name}</span></h2>
              <h2 className='text-xs !font-bold'>Account Number: <span className='font-normal'>{loggedCreatorData.creator.account_number}</span></h2>
              <h2 className='text-xs !font-bold'>Account Name: <span className='font-normal'>{loggedCreatorData.creator.account_name}</span></h2>
          </div> : <></>
        }
        
        <div className='flex flex-col p-4 rounded-[16px] bg-white mt-5'>
          <div className='flex flex-col p-4 pb-6 border-[4px] border-[#D2D8C7] rounded-[24px] my-3'>
            <h2 className='font-bold text-base'>Bank Transfer</h2>
            <div className="flex gap-3 items-center mt-[5px] py-[5px]">
              <img src={check} className="w-[16px] h-[16px]" alt=""/>
              <h3 className="text-[#6F7466] text-xs">Submit Nigerian bank account details</h3>
            </div>

            <div className="flex items-center gap-3 mt-[5px]">
              <img src={check} alt="" className="w-[16px] h-[16px]" />
              <h3 className="text-[#6F7466] text-xs">1-2 <strong>business</strong> days to receive payments automatically.</h3>
            </div>
          </div>

          <div className='main-complete' style={{padding: '10px', paddingTop: '10px'}}>
            <form className="" action="#" onSubmit={e => onSubmit(e)}>
                <h1 className='font-bold mb-1'>Update your Account</h1>
                <div className='mb-5'>
                    <label className="font-semibold" style={{fontWeight: '600px'}}>Choose your bank</label>
                    <Select className='w-full' options={banknames} value={bankname} onChange={changeHandler} required/>
                </div>
                <div>
                    <label htmlFor="email" className="font-semibold" style={{fontWeight: '600px'}}>
                        Account Number
                    </label>
                    <p className='text-xs'>Your bank account number. Make sure this is correctly inputed.</p>
                    <div className="mt-2">
                    <input
                        id="name"
                        name="name"
                        type="number"
                        required
                        value={acc_no}
                        onChange={e => setAcc_no(e.target.value)}
                        className=""
                    />
                    </div>
                </div>

                <div className={loading || !submittable ? 'submit-com-dis' : 'submit-comp'}>
                  {loading ? 
                    <div
                        className="flex ml-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span>
                      </div> :
                    <button
                        type="submit"
                        disabled={loading || !submittable}
                        className="complete-button"
                    >
                      Update
                    </button>
                  }
                    
                </div>
              </form>
            </div>
        </div>
        
        
      </div>
      </main>
    );
  }
  
  export default PaymentSettings;
  