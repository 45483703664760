import React, { useState } from "react";
import { TECollapse } from "tw-elements-react";

export default function Faqs(): JSX.Element {
  const [activeElement, setActiveElement] = useState("");

  const handleClick = (value: string) => {
    if (value === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(value);
    }
  };
  return (
    <>
    <div className="rounded-lg border border-t-0 border-neutral-200 bg-[#D2D8C7] mb-4">
        <h2 className="mb-0" id="headingTwo">
          <button
            className='group relative font-bold flex w-full items-center rounded-t-[15px] border-0 bg-[#D2D8C7] px-5 py-4 text-left text-base text-[#121B00] transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none'
            type="button"
            onClick={() => handleClick("element1")}
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Why do I even need Prymshare?
            <span
              className='ml-auto h-5 w-5 shrink-0 fill-none transition-transform duration-200 ease-in-out motion-reduce:transition-none'
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </button>
        </h2>
        <TECollapse
          show={activeElement === "element1"}
          className="!mt-0 !rounded-b-none !shadow-none"
        >
          <div className="px-5 py-4 !text-sm">
          Right now, every time you’ve got something new to share, you have to go to every single one of your channels to change the link in each of your bios. It’s time-consuming and complicated – making it so much harder to keep everything up to date.

<br /><br />A link in bio tool means you never have to compromise, or remove one link from your bio so you can add another. You can keep everything you want to share online in one link. When you’ve got a change, you only ever have to make it once.
          </div>
        </TECollapse>
      </div>

    
      
      <div className="rounded-lg border border-t-0 border-neutral-200 bg-[#D2D8C7] mb-4">
        <h2 className="mb-0" id="headingTwo">
          <button
            className='group relative font-bold flex w-full items-center rounded-t-[15px] border-0 bg-[#D2D8C7] px-5 py-4 text-left text-base text-[#121B00] transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none'
            type="button"
            onClick={() => handleClick("element2")}
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Can I sell my content on Prymshare?
            <span
              className='ml-auto h-5 w-5 shrink-0 fill-none transition-transform duration-200 ease-in-out motion-reduce:transition-none'
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </button>
        </h2>
        <TECollapse
          show={activeElement === "element2"}
          className="!mt-0 !rounded-b-none !shadow-none"
        >
          <div className="px-5 py-4 !text-sm">
            Yes! Prymshare provides you with multiple means of monetization on your content, virtual events tickets, and other digital products
          </div>
        </TECollapse>
      </div>

      <div className="rounded-lg border border-t-0 border-neutral-200 bg-[#D2D8C7] mb-4">
        <h2 className="mb-0" id="headingTwo">
          <button
            className='group relative font-bold flex w-full items-center rounded-t-[15px] border-0 bg-[#D2D8C7] px-5 py-4 text-left text-base text-[#121B00] transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none'
            type="button"
            onClick={() => handleClick("element3")}
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Is Prymshare free?
            <span
              className='ml-auto h-5 w-5 shrink-0 fill-none transition-transform duration-200 ease-in-out motion-reduce:transition-none'
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </button>
        </h2>
        <TECollapse
          show={activeElement === "element3"}
          className="!mt-0 !rounded-b-none !shadow-none"
        >
          <div className="px-5 py-4 !text-sm">
            Prymshare is totally free to use. You can showcase your online presence, and sell with the free plan. <a href='#'>Learn more on the Pricing</a>
          </div>
        </TECollapse>
      </div>

      <div className="rounded-lg border border-t-0 border-neutral-200 bg-[#D2D8C7]">
        <h2 className="mb-0" id="headingTwo">
          <button
            className='group relative font-bold flex w-full items-center rounded-t-[15px] border-0 bg-[#D2D8C7] px-5 py-4 text-left text-base text-[#121B00] transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none'
            type="button"
            onClick={() => handleClick("element4")}
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Can I use Prymshare on all social media?
            <span
              className='ml-auto h-5 w-5 shrink-0 fill-none transition-transform duration-200 ease-in-out motion-reduce:transition-none'
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </button>
        </h2>
        <TECollapse
          show={activeElement === "element4"}
          className="!mt-0 !rounded-b-none !shadow-none"
        >
          <div className="px-5 py-4 !text-sm">
            Yes! Prymshare is also a link in bio tool to be used across all existing platforms. Our goal is to unify links to your online presence
          </div>
        </TECollapse>
      </div>
    </>
  );
}