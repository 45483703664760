import { useEffect, useState } from 'react';
import './customize.css';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { iscreator, theme, loggeduname, loggedcreatordata  } from "../../state/atoms/Creator";

import Custom from '../../images/custom.png';
import Pro from '../../images/Flash.png';


function Customize() {
    const [loggedCreatorUname, setLoggedCreatorUname] = useRecoilState(loggeduname);
    const [loggedCreatorData, setLoggedCreatorData] = useRecoilState(loggedcreatordata);
    const [selectedTheme, setSelectedTheme] = useState(undefined);
    const [firstTheme, setFirstTheme] = useState('');
    let currentTheme = loggedCreatorData.creator.theme;

    useEffect (() => {
        setSelectedTheme(currentTheme);
        console.log(currentTheme)
    }, [currentTheme])

    const saveTheme = () => {
        // console.log('saving')
        const config = {
            headers: {
                'Content-Type': 'multipart/formdata',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                // 'Accept': 'multipart/formdata'
            } 
        }; 
        if (selectedTheme != undefined) {
             axios.put(`${process.env.REACT_APP_API_URL}/api/profile/theme-edit/${selectedTheme}`, { }, config)
                .then(response => {
                    console.log('Theme saved:', response.data);
                })
                .catch(error => {
                    alert("Oops! Something went wrong. Please try again!")
                    setSelectedTheme(currentTheme);
                    console.error('Error saving theme:', error);
                });
            }
       
      };

    useEffect (() => {
        if (selectedTheme != undefined){
            saveTheme();
        }
        
    }, [selectedTheme])

    useEffect (() => {
        console.log(firstTheme);
    }, [firstTheme])


    let url = `https://prymshare.co/${loggedCreatorUname}`
    return (
        <main className="customize">
            <div className='customizer'>
                <h1>Choose your Look!</h1>
                <p>Select a background style that suites you!</p>

                <div className="colors">
                    <div onClick={() => setSelectedTheme('purple')} className={selectedTheme === 'purple' ? "color purple selected" : "color purple"} />
                    <div onClick={() => setSelectedTheme('green')} className={selectedTheme === 'green' ? "color green selected" : "color green"} />
                    <div onClick={() => setSelectedTheme('orange')} className={selectedTheme === 'orange' ? "color orange selected" : "color orange"} />
                    <div onClick={() => setSelectedTheme('grey')} className={selectedTheme === 'grey' ? "color lgrey selected" : "color lgrey"} />
                    <div onClick={() => setSelectedTheme('dark')} className={selectedTheme === 'dark' ? "color black selected" : "color black"} />
                </div>

                <a className='preview' href={url} target='_blank'>Preview</a>
            </div>

            <div className='no-limits'>
                <div className='custom'><img src={Custom} /></div>
                <div className='proo-pro'>
                    <h2>Create with no limit. We've got more customizations coming soon.</h2>
                    <p>We are also building on much more with PRO!</p>
                    <div className="pro-soon pro-sooon">
                        <img src={Pro} alt='pro' />
                        <p>Pro Coming Soon</p>
                    </div>
                </div>
            </div>
            
        </main>
    );
  }
  
  export default Customize;
  