import axios from 'axios';


export const checkCreator = async(setCreatorState, id, setCreatorTheme, setCreatorPageData) => {
	// if (localStorage.getItem('access')) {
	let response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let data = await response.json();
  setCreatorState(data.iscreator);
  setCreatorTheme(data.creator.theme);
  setCreatorPageData(data);
  // console.log(data);
  // console.log(data.creator.theme);

  // if (data.code !== 'token_not_valid') {
  //   setAuthState(true);
  //   // console.log(data.code);
  // } else {
  //   // console.log(data);
  //   setAuthState(false);
  //   // console.log('error');
  // }
}


export const loadProfile = async(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
	// console.log('Checking in  progress')
	if (localStorage.getItem('access')) {
		let response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile/`, {
	    method: "GET",
	    headers: {
	      "Content-Type": "application/json",
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
	    },
	    
	  });
    // console.log(response.status);
    if (response.status === 200) {
      let data = await response.json();
      if (data.creator.display_name == '' || data.creator.display_name == null || data.creator.display_name == undefined) {
        setLoggedCreator(false);
      } else {
        setLoggedCreator(true);
      }
      setLoggedCreatorData(data);
      setLoggedCreatorDname(data.creator.display_name);
      setLoggedCreatorUname(data.creator.creator_username);
      console.log(data.creator);
    } else {
      setLoggedCreator(false);
    };
	  
	}	else {
		setLoggedCreator(false);
	}
	console.log('Checked!')
  
};


export const CreateCreatorProfile = async(formData, navigate) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/profile/create/`, formData, config)
    .then((res) => {
      // console.log('done');
      navigate('/add-socials');
    })
    .catch((err) => {
      console.log("Error", err);
      alert('Something went wrong, please try again!')
    });
};

export const addCreatorSocials = async(formData, navigate, setLoading) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/profile/add-socials/`, formData, config)
    .then((res) => {
      // console.log('done');
      setLoading(false);
      navigate('/me/appearance');
      
    })
    .catch((err) => {
      console.log("Error", err);
      setLoading(false);
      alert('Something went wrong, please try again!')
    });
};


export const EditCreatorProfile = async(formData, socialsFormData, username, socialsId, setLoadingHeader, setShowModal1, navigate, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .patch(`${process.env.REACT_APP_API_URL}/api/profile/creator-edit/${username}`, formData, config)
    .then((res) => {
      if(socialsId != null) {
        axios
        .patch(`${process.env.REACT_APP_API_URL}/api/profile/socials-edit/${socialsId}`, socialsFormData, config)
        .then((res) => {
          setLoadingHeader(false);
          setShowModal1(false);
          loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
        })
        .catch((err) => {
          console.log("Error", err);
          setLoadingHeader(false);
          alert('Something went wrong, please try again!')
        });
      } else {
        addCreatorSocials(socialsFormData, navigate, setLoadingHeader)
      }
      

      
      // navigate('/add-socials');
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingHeader(false);
    });
};


export const loadLinks = async(setCreatorLinks) => {
	// if (localStorage.getItem('access')) {

	let response = await fetch(`${process.env.REACT_APP_API_URL}/api/content/get-linkbuttons/`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
      'Accept': 'application/json'
    },
  });
  let data = await response.json();
  setCreatorLinks(data)
  console.log(data);

}


export const addCreatorLinks = async(formData, setShowModal, setCreatorLinks, setLoading, setPrologo, setPrologoUrl, setLinktitle, setUrl) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/content/linkbuttons/`, formData, config)
    .then((res) => {
      loadLinks(setCreatorLinks)
      setLoading(false);
      setPrologo(null);
      setPrologoUrl();
      setLinktitle('');
      setUrl('');
      setShowModal(false)
    })
    .catch((err) => {
      console.log("Error", err);
      setLoading(false);
      alert('Something went wrong, please try again!')
    });
};

export const editCreatorLinks = async(formData, linkId, setShowModal, setCreatorLinks, setLoading, setPrologo, setPrologoUrl, setLinktitle, setUrl) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .patch(`${process.env.REACT_APP_API_URL}/api/content/linkbuttons/${linkId}/`, formData, config)
    .then((res) => {
      loadLinks(setCreatorLinks)
      setLoading(false);
      setPrologo(null);
      setPrologoUrl();
      setLinktitle('');
      setUrl('');
      setShowModal(false)
    })
    .catch((err) => {
      console.log("Error", err);
      setLoading(false);
      alert('Something went wrong, please try again!')
    });
};


export const deleteCreatorLinks = async(linkId, setCreatorLinks, setOpen, setLoadingLDelete) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/content/linkbuttons/${linkId}/`, config)
    .then((res) => {
      loadLinks(setCreatorLinks)
      setLoadingLDelete(false);
      setOpen(false)
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingLDelete(false);
      alert('Something went wrong, please try again!')
    });
};


export const loadPageStructure = async(loggedCreatorUname, setCreatorPS) => {

	let response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile/pagestruct/${loggedCreatorUname}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let data = await response.json();
  setCreatorPS(data.section_order)
  console.log(data.section_order);

}

export const EditPageStructure = async(formData, pageStructId, loggedCreatorUname, setCreatorPS) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  
  axios
    .patch(`${process.env.REACT_APP_API_URL}/api/profile/pagestruct-edit/${pageStructId}`, formData, config)
    .then((res) => {
      // setLoadingHeader(false);
      // setShowModal1(false);
      loadPageStructure(loggedCreatorUname, setCreatorPS);
    })
    .catch((err) => {
      console.log("Error", err);
      // setLoadingHeader(false);
      alert('Something went wrong, please try again!')
    });

      
      // navigate('/add-socials');
    
};

export const CreateTextBlock = async(formData, setLoadingSubmit, setShowModal1, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/content/textblock/create/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingSubmit(false);
      setShowModal1(false);
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingSubmit(false);
      alert('Something went wrong, please try again!')
    });
};

export const UpdateTextBlock = async(formData, textBlockId, setLoadingSubmit, setShowModal1, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .patch(`${process.env.REACT_APP_API_URL}/api/content/textblock/${textBlockId}/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingSubmit(false);
      setShowModal1(false);
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingSubmit(false);
      alert('Something went wrong, please try again!')
    });
};

export const CreateGiftBlock = async(formData, setLoadingGSubmit, setShowGift, setShowModal5, setPrice, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/content/giftblock/create/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingGSubmit(false);
      setPrice(0);
      setShowGift(false)
      setShowModal5(false);
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingGSubmit(false);
      alert('Something went wrong, please try again!')
    });
};

export const UpdateGiftBlock = async(formData, giftBlockId, setLoadingGSubmit, setShowGift, setShowModal5, setPrice, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .patch(`${process.env.REACT_APP_API_URL}/api/content/giftblock/${giftBlockId}/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingGSubmit(false);
      setPrice(0);
      setShowGift(false)
      setShowModal5(false);
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingGSubmit(false);
      alert('Something went wrong, please try again!')
    });
};

export const DeleteGiftBlock = async(giftBlockId, setLoadingGSubmit, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname, setOpen) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/content/giftblock/${giftBlockId}/`, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingGSubmit(false);
      setOpen(false)
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingGSubmit(false);
      alert('Something went wrong, please try again!')
    });
};


export const addCreatorPaywall = async(formData, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname, setLoadingAdd, setPrologo, setPrologoUrl, setText, setLink, setDesc, setPrice, setActiveElement) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/content/paywall/create/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingAdd(false);
      setActiveElement('');
      setPrologo(null);
      setPrologoUrl();
      setText('');
      setLink('');
      setDesc('');
      setPrice(0);
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingAdd(false);
      alert('Something went wrong, please try again!')
    });
};


export const deleteCreatorPaywall = async(wallId, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/content/paywall/${wallId}/`, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
    })
    .catch((err) => {
      console.log("Error", err);
      alert('Something went wrong, please try again!')
    });
};

// VIDEO SECTION ACTIONS
export const CreateVideoSection = async(formData, setLoadingAddVS, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/content/video-sec/create/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingAddVS(false);
      alert('Header saved!')
      // setShowModal5(false);
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingAddVS(false);
      alert('Something went wrong, please try again!')
    });
};

export const UpdateVideoSection = async(formData, videoSectionId, setLoadingAddVS, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .patch(`${process.env.REACT_APP_API_URL}/api/content/video-section/${videoSectionId}/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingAddVS(false);
      alert('Header saved!')
      // setShowModal5(false);
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingAddVS(false);
      alert('Something went wrong, please try again!')
    });
};

export const addVideoPost = async(formData, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname, setLoadingAddVP, setPostText, setLink, setActiveElement) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/content/video-post/create/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingAddVP(false);
      setActiveElement('');
      setPostText('');
      setLink('');
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingAddVP(false);
      alert('Something went wrong, please try again!')
    });
};


export const deleteVideoPost = async(videoId, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/content/video-post/${videoId}/`, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
    })
    .catch((err) => {
      console.log("Error", err);
      alert('Something went wrong, please try again!')
    });
};


// GALLERY SECTION ACTIONS

export const CreateGallerySection = async(formData, setLoadingAddGS, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/content/gallery-sec/create/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingAddGS(false);
      alert('Header saved!')
      // setShowModal5(false);
    })
    .catch((err) => {
      console.log("Error", err);
      alert('An error occured, please try again!');
      setLoadingAddGS(false);
      alert('Something went wrong, please try again!')
    });
};

export const UpdateGallerySection = async(formData, gallerySectionId, setLoadingAddGS, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .patch(`${process.env.REACT_APP_API_URL}/api/content/gallery-section/${gallerySectionId}/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingAddGS(false);
      alert('Header saved!')
      // setShowModal5(false);
    })
    .catch((err) => {
      console.log("Error", err);
      setLoadingAddGS(false);
      alert('Something went wrong, please try again!')
    });
};

export const addGalleryPost = async(formData, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname, setLoadingAddGP, setImage, setImageUrl, setActiveElement) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/content/gallery-post/create/`, formData, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
      setLoadingAddGP(false);
      alert('Image added!')
      setActiveElement('');
      setImage(null);
      setImageUrl();
    })
    .catch((err) => {
      console.log("Error", err);
      alert('An error occured, please try again! If error persists, contact support@prymshare.co');
      setLoadingAddGP(false);
      alert('Something went wrong, please try again!')

    });
};


export const deleteGalleryPost = async(galleryId, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname) => {
  const config = {
    headers: {
        'Content-Type': 'multipart/formdata',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        // 'Accept': 'multipart/formdata'
    } 
  }; 
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/content/gallery-post/${galleryId}/`, config)
    .then((res) => {
      loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
    })
    .catch((err) => {
      console.log("Error", err);
      alert('An error occured, please try again! If error persists, contact support@prymshare.co');
    });
};