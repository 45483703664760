import { useEffect, useState } from 'react';
// import Navbar from '../components/header/navbar';
import {
	Outlet,
	Link,
	useParams,
	useLocation,
	useNavigate

} from "react-router-dom";
import queryString from 'query-string';

import { useRecoilState, useRecoilValue } from 'recoil';
import { authenticated, access, refresh, userid, firstname, lastname, email } from "./../state/atoms/Authenticated";

import { googleAuthenticate, checkAuth, loadUser, logout } from './../state/actions/auth'

import { iscreator, theme, loggedcreator, loggedcreatordata, loggeddname, loggeduname, creatorlinks, creatorps } from "./../state/atoms/Creator";
import { checkCreator, loadProfile, loadLinks, loadPageStructure } from './../state/actions/creator';

import ProfileNav from '../components/header/profilenavbar.tsx';



const ProfileSettingsLayout = () => {
	const { id } = useParams();
	let location = useLocation();
	let navigate = useNavigate();

	const [authState, setAuthState] = useRecoilState(authenticated);
	const [accessState, setAccessState] = useRecoilState(access);
	const [refreshState, setRefreshState] = useRecoilState(refresh);
	const isAuth = useRecoilValue(authenticated);

	const [userId, setUserId] = useRecoilState(userid);
	const [firstName, setFirstName] = useRecoilState(firstname);
	const [lastName, setLastName] = useRecoilState(lastname);
	const [userEmail, setUserEmail] = useRecoilState(email);

	const [creatorState, setCreatorState] = useRecoilState(iscreator);
	const [creatorTheme, setCreatorTheme] = useRecoilState(theme);
	const [loggedCreator, setLoggedCreator] = useRecoilState(loggedcreator);
	const [loggedCreatorData, setLoggedCreatorData] = useRecoilState(loggedcreatordata);
	const [loggedCreatorDname, setLoggedCreatorDname] = useRecoilState(loggeddname);
	const [loggedCreatorUname, setLoggedCreatorUname] = useRecoilState(loggeduname);
	const [creatorLinks, setCreatorLinks] = useRecoilState(creatorlinks);
	const [creatorPS, setCreatorPS] = useRecoilState(creatorps);
	// const isCreator = useRecoilValue(iscreator);
	const Theme = useRecoilValue(theme);


	const isCreator = useRecoilValue(loggedcreator);

	// useEffect(() => {
	// 	if (!isCreator && isCreator !='loading') {
	// 		return navigate("/complete-profile/");
	// 	}
	// }, [isCreator]);


	useEffect(() => {
        const values = queryString.parse(location.search);
        const state = values.state ? values.state : null;
        const code = values.code ? values.code : null;

        console.log('State: ' + state);
        console.log('Code: ' + code);

        if (state && code) {
        	// console.log(code+'again');
            googleAuthenticate(state, code, setAuthState, setAccessState, setRefreshState);
        }

        // console.log(window.location.pathname);
        
    }, [location]);


	useEffect(() => {
		// const username = match.params.id;
		console.log('Checking verification for user!');
      	checkAuth(setAuthState);
		loadProfile(setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
		
		loadPageStructure(loggedCreatorUname, setCreatorPS)
      	if (id != undefined) {
      		checkCreator(setCreatorState, id, setCreatorTheme);
      	}
		loadUser(setUserId, setFirstName, setLastName, setUserEmail);
      	
      	console.log(id);
  	}, [loggedCreatorUname]);

	useEffect(() => {
		loadLinks(setCreatorLinks)

  	}, [creatorLinks.length]);

	return (
    <div>
    	<ProfileNav loggedCreatorUname={loggedCreatorUname} loggedCreatorDname={loggedCreatorDname} loggedCreatorData={loggedCreatorData}  />
		<Outlet />
    </div>
	);
}

export default ProfileSettingsLayout;